import React, { useState, useRef, useContext } from 'react';
import './profile-setup.scss';
import { AuthUser, MusicianProfileData, User } from '../../interfaces';
import { useHistory } from 'react-router-dom';
import urls from '../../urls';
import API from '../../firebase';
import { previewPhoto, getLocationCoords, showInfoAlert, showErrorAlert } from '../../utilities';
import useTags from '../../hooks/genre-tags';
import useUrlVerify from '../../hooks/verifyProfileUrl';
import { store } from 'react-notifications-component';
import appContext from '../../appContext';

const useSteps = (user: AuthUser) => {
    const [data, setData] = useState<MusicianProfileData>({
        stageName: '',
        website: '',
        location: {
            street_city_state: '',
            coordinate: null
        },
        bio: '',
        experience: '',
        tags: [],
        photoURL: '',
        coverPhotoURL: '',
        type: 'musician',
        url: '',
        applications: []
    });
    const [currentStep, setCurrent] = useState(0);

    const useValidator = (validator: () => false | MusicianProfileData) => {
        const Navigation = () => (
            <div className="nav_">
                <button className="back link-button" onClick={() => {
                    setCurrent(prev => prev > 0 ? prev - 1 : prev);
                }}>Back</button>
                <button className="next link-button" onClick={() => {
                    let data = validator();
                    if (data) {
                        setData(prev => ({ ...prev, ...data }))
                        setCurrent(prev => prev < 2 ? prev + 1 : prev);
                    } else {
                        window.alert('All required fields must be filled');
                    }
                }}>Next</button>
            </div>
        )
        return {
            Nav: Navigation
        }
    }

    const Step1 = () => {
        const formRef = useRef<HTMLFormElement>(null);

        const validator = (ref: React.MutableRefObject<HTMLFormElement | null>): false | MusicianProfileData => {
            if (ref.current?.checkValidity()) {
                let inputs = ref.current.querySelectorAll('input');
                let data: any = {};
                for (var i = 0; i < inputs.length; i++) {
                    if (inputs[i].name === 'location') {
                        data.location = {
                            street_city_state: inputs[i].value,
                            coordinate: null
                        }
                    } else {
                        data[inputs[i].name] = inputs[i].value;
                    }
                }
                return data as MusicianProfileData;
            }
            return false;
        }

        const { Nav } = useValidator(() => validator(formRef));
        return (
            <>
                <h2>Tell us more about you.</h2>
                <form onSubmit={(e) => e.preventDefault()} ref={formRef}>
                    <div className="form-group">
                        <label htmlFor="stage">Stage Name</label>
                        <input type="text" placeholder="Stage Name" defaultValue={data.stageName} required name="stageName" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="location">Location (where are you looking for gigs?)</label>
                        <input type="text" placeholder="Street,City,State" defaultValue={data.location?.street_city_state} required name="location" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="website">Website Link (Optional)</label>
                        <input type="text" name="website" defaultValue={data.website} placeholder="Youtube,Soundcloud,Spotify" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="experience">Years of Experience (Optional)</label>
                        <input type="number" name="experience" defaultValue={data.experience} placeholder="Years of Experience" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="bio">Quick Bio (Optional)</label>
                        <input type="text" name="bio" defaultValue={data?.bio} placeholder="What do you want venues to know about you?" />
                    </div>
                </form>
                <Nav />
            </>
        )
    }

    const Step2 = () => {
        const { selectedTags, TagsComponent } = useTags(data.tags);
        const { Nav } = useValidator(() => ({ tags: selectedTags }));

        return (
            <div className="tags-select">
                <h2>Select or search tags that define your style</h2>
                <TagsComponent />
                <Nav />
            </div>
        )
    }

    const Step3 = () => {
        const profileRef = useRef<HTMLInputElement>(null);
        const coverRef = useRef<HTMLInputElement>(null);
        const { getUrl, UrlComponent } = useUrlVerify(undefined,data.stageName);
        const history = useHistory();
        const { setUser } = useContext(appContext);

        const handleProfileCreation = async () => {
            let id;
            try {
                let profilePhoto = profileRef.current?.files;
                let coverPhoto = coverRef.current?.files;
                id = showInfoAlert('we are publishing your profile...','Processing',0);
                let photoURL = profilePhoto?.length ? await API.saveImage(profilePhoto[0], 'profile') as string : '';
                let coverPhotoURL = coverPhoto?.length ? await API.saveImage(coverPhoto[0], 'cover') as string : '';
                let url = getUrl();
                let location_coords = await getLocationCoords(data.location!.street_city_state);
                let new_data = { ...data,media: [], email: user.email, location: { street_city_state: data.location!.street_city_state, coordinate: location_coords }, coverPhotoURL, photoURL, url: (url ? url : '') };
                await API.setUserProfile(new_data,user.uid);
                setUser({ ...user,...new_data } as User);
                store.removeNotification(id);
                showInfoAlert('Redirecting you to sign-in page','Profile saved');
                history.push(urls.HOME)
            } catch (e) {
                const message = e.message;
                id && store.removeNotification(id);
                showErrorAlert(message);
            }
        };

        const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let files = e.target.files;
            if(files){
                previewPhoto(files[0], e.target.parentElement);
            }
        }

        return (
            <div>
                <h2>Add a profile and cover photo</h2>
                <div className="profile-card">
                    <div className="profile-card--images">
                        <label className="profile-card--images__cover">
                            <input type="file" hidden accept="image/*" ref={coverRef} onChange={handleImageChange} name="cover" />
                        </label>
                        <label className="profile-card--images__profile">
                            <input type="file" hidden name="profile" ref={profileRef} onChange={handleImageChange} accept="image/*" />
                        </label>
                    </div>
                    <div className="profile-card--text">
                        <h3>{data.stageName}</h3>
                        <p>{data.location?.street_city_state}</p>
                    </div>
                </div>
                <UrlComponent />
                <button onClick={handleProfileCreation} className="rounded primary-button">Create my Account</button>
            </div>
        )
    }

    const steps = [Step1, Step2, Step3];

    return steps[currentStep];
}

const MusicianProfileSetup = ({ user }: { user: AuthUser}) => {
    const Step = useSteps(user);

    return (
        <div className="container musician-create-profile">
            <Step />
        </div>
    )
}

export default MusicianProfileSetup;