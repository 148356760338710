import React from 'react';
import { Link } from 'react-router-dom';
import urls from '../urls';
import './sign-up.scss';
import Navigation from './components/navigation';

const SignUp = () => {
    return (
        <>
        <Navigation noProfile />
        <section className="container sign-up">
            <div className="title">
                <h1>Hello!</h1>
                <p>Let's get to know each other</p>
            </div>
            <div className="links">
                <div className="musician-link">
                    <img src={require('../assets/music.png')} alt="" />
                    <Link to={urls.MUSICIAN_SIGNUP}>
                       <div><b>I am a musician</b></div>
                       <div><i>I'm looking for local gigs</i></div>
                    </Link>
                </div>
                <div>
                    <img src={require('../assets/venue.png')} alt="" />
                    <Link to={urls.VENUE_SIGNUP}>
                        <div><b>I have a venue</b></div>
                        <div><i>I'm looking for local musicians</i></div>
                    </Link>
                </div>
            </div>
        </section>
        </>
    )
}

export default SignUp;