import React, { useEffect, useRef, useState } from 'react';
import './genre-tags.scss'

const useTags = (tags?: string[]) => {
    const [selectedTags, setSelected] = useState<string[]>([]);
    const [allTags] = useState<string[]>(require('../data/genres.json'));
    const suggested = ['alternative','rock','funk','jazz','electronic','country','pop','classical'];
    const [suggestedTags, setSuggested] = useState<string[]>(suggested);
    const query = useRef('');


    useEffect(() => {
        if (tags) {
            setSelected(tags);
            setSuggested(prev => prev.filter(item => !tags.includes(item)))
        }
    }, [tags]);

    const TagsComponent = ({ mode, placeholder }: { mode?: 'search', placeholder?: string }) => {
        const [searching, setSearching] = useState(false);
        const [results, setResults] = useState<string[]>([]);

        const addTag = (tag: string) => {
            if (selectedTags.length < 6) {
                setSelected(prev => ([...prev, tag]))
                setSuggested(prev => prev.filter(item => item !== tag))
            } else {
                window.alert('A maximum of 6 tags are allowed');
            }
        }

        const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
            let _query = e.target.value;
            query.current = _query;
            if (_query === '') {
                setSearching(false);
                setResults([])
            } else {
                setSearching(true);
                setResults(allTags.filter(item => item.includes(_query)))
            }
        }

        const selectSuggestedTag = (genre: string) => {
            addTag(genre);
            setResults(prev => prev.filter(item => item !== genre))
        }

        const renderSearchResult = () => {
            return (searching ?
                <ul className="suggested-results text-14">
                    {results.slice(0, 10).map((genre, i) => <li value={genre} key={i} onClick={() => selectSuggestedTag(genre)}>{genre}</li>)}
                </ul> : null)
        }

        const deleteTag = (tag: string) => {
            setSelected(prev => prev.filter(item => item !== tag))
            setSuggested(prev => suggested.includes(tag) ? ([tag, ...prev]) : prev)
        }

        return (
            <div className="genre-tags-hook">
                <div className="search-section">
                    <input type="text" {...{ placeholder }} className="tag_search" onChange={handleSearch} />
                    <button className="link-button" onClick={() => { addTag(query.current) }}>Add Tag</button>
                </div>
                {renderSearchResult()}
                <div className="genre-tags-hook__selected-tags">
                    {selectedTags.map((tag, i) => (<div className="genre-tag removable" onClick={() => deleteTag(tag)} key={i}>{tag}</div>))}
                </div>
                {
                    !mode &&
                    <div className="suggested-tags__container tags">
                        <p>Suggested Tags</p>
                        {
                            suggestedTags.map((tag, i) => (
                                <div className="genre-tag" onClick={() => addTag(tag)} key={i}>
                                    {tag}
                                </div>
                            ))
                        }
                    </div>
                }
            </div>
        )
    }
    return {
        TagsComponent,
        selectedTags
    }
}

export default useTags;