import React, { useState, useEffect, useContext, useRef, FormEvent } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import appContext from '../../appContext';
import API from '../../firebase';
import useTags from '../../hooks/genre-tags';
import useModal from '../../hooks/modal';
import { Gig, Slot } from '../../interfaces';
import urls from '../../urls';
import { generateTimeSlots, previewPhoto, showErrorAlert, addGigCoverStyling, getLocationCoords } from '../../utilities';
import Navigation from '../components/navigation';
import './edit-gig.scss';
import useMediator from '../../hooks/mediator';

const EditGig = (props: RouteComponentProps<{}>) => {
    let { id } = props.match.params as any;
    const { user } = useContext(appContext);
    const [gig, setGig] = useState<Gig>();
    const imageRef = useRef<HTMLDivElement>(null);
    const stageImageRef = useRef<HTMLDivElement>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const [newImages, setNewImages] = useState<{
        gig_cover: File | null,
        stage_image: File | null
    }>({
        gig_cover: null,
        stage_image: null
    });
    const history = useHistory();
    const { selectedTags, TagsComponent } = useTags(gig?.tags);
    const { Modal, setShowModal } = useModal();
    const mediator = useMediator();
    const [addressHasChanged, setAddressChanged] = useState(false);

    useEffect(() => {
        const handleGigRetrieval = async () => {
            let gig_ = (await API.collectGigs([id]))[0];
            setGig(gig_)
        }

        handleGigRetrieval();
    }, [id])

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        setGig(prev => ({ ...prev, [target.name]: target.value }) as Gig)
        if (target.name === 'address' && !addressHasChanged) {
            setAddressChanged(true);
        }
    }

    const findIntersectingSlots = () => {
        let intersection: Slot[] = [];
        gig!.dates!.forEach((d: string) => {
            let related_slot = gig!.slots?.find(obj => obj.date === d);
            related_slot && intersection.push(related_slot);
        })
        return intersection;
    }

    const verifyDates = () => {
        if (!gig) {
            return;
        }
        gig?.dates!.forEach(d => {
            if (isNaN(Date.parse(d))) {
                throw new Error(`Invalid Date ${d}. Enter a valid date. For example, 03 Jan 2020`)
            }
            let today = new Date();
            let givenDate = new Date(d);
            if (today > givenDate && today.toDateString() !== givenDate.toDateString()) {
                throw new Error(`${d} is invalid because ${d} is an earlier date than today (${new Date().toDateString()})`)
            }
        });
        //check for frequency misaligments
        let onlyTwoDatesRequired = gig?.frequency === 'weekly' || gig?.frequency === 'monthly';
        if (onlyTwoDatesRequired && gig.dates?.length !== 2) {
            throw new Error('Only two dates must be specified for weekly and monthly frequencies: The start date and end date.')
        }
        if (gig.frequency === 'once' && gig.dates?.length !== 1) {
            throw new Error('Only one date must be specified');
        }
    }

    const handleSlotsUpdate = (): Slot[] => {
        verifyDates();
        let intersection = findIntersectingSlots();
        const notInIntersection = (s: Slot) => {
            return !intersection.some(a => a.date === s.date)
        }
        const sortedDates = gig!.dates!.sort((a, b) => (Date.parse(a) - Date.parse(b)));
        let new_slots: Slot[] = generateTimeSlots(gig!.frequency!, sortedDates).filter(s => notInIntersection(s));
        return [...intersection, ...new_slots].sort((a, b) => (Date.parse(a.date) - Date.parse(b.date)));
    }

    const handleSaveEdit = async (e: FormEvent) => {
        e.preventDefault();
        const form = formRef.current;
        if (form && form.checkValidity() && gig && user) {
            try {
                let new_slots = handleSlotsUpdate();
                let location = gig.location;
                if (addressHasChanged) {
                    let coords = await getLocationCoords(gig.address!);
                    location!.coordinate = coords;
                    location!.street_city_state = gig.address!;
                }
                let updatedGig = { ...gig, ...newImages, slots: new_slots, location };
                await API.updateGig({ gig_cover: newImages.gig_cover !== null, stage_image: newImages.stage_image !== null }, updatedGig);
                history.push(`${urls.VIEW_GIG}/${id}`);
            } catch (e) {
                const { message } = e;
                showErrorAlert(message)
            }
        } else {
            showErrorAlert('Please make sure all required fields are filled');
        }
    }

    const handleSetlistChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        setGig(prev => ({ ...prev, setlist: target.value.split(',') }) as Gig);
    }

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const { files } = target;
        if (files) {
            setNewImages(prev => ({ ...prev, [target.name]: files[0] }))
            previewPhoto(files[0], target.name === 'gig_cover' ? imageRef.current : stageImageRef.current)
        }
    }

    const handleFrequencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;
        setGig(prev => prev ? ({ ...prev, frequency: value }) as Gig : prev)
    }

    const handleGigDelete = async () => {
        try {
            let confirmed = window.confirm("Are you sure you want to delete this gig?");
            if (!confirmed) return;
            mediator.deleteGig(gig!)
            history.push(urls.HOME);
        } catch (e) {
            const { message } = e;
            showErrorAlert(message);
        }
    }

    const handleTagsSave = () => {
        setGig(prev => ({ ...prev!, tags: selectedTags }));
        setShowModal(false);
    }

    const handleDatesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dates = e.target.value.split(',');
        setGig(prev => ({ ...prev!, dates }))
    }

    return (
        <>
            <Modal collapsible>
                <div className="edit-gig__update-tags">
                    <h2>Select new tags</h2>
                    <TagsComponent />
                    <div className="options">
                        <button onClick={handleTagsSave} className="link-button">Save</button>
                        <button onClick={() => setShowModal(false)} className="link-button">Cancel</button>
                    </div>
                </div>
            </Modal>
            <Navigation withShadow />
            <div ref={imageRef} style={addGigCoverStyling(gig?.gig_cover_url)}></div>
            {gig ?
                <form onSubmit={handleSaveEdit} ref={formRef} className="container edit-gig">
                    <label className="change-gig-photo text-12">
                        <span>Change gig photo</span>
                        <input type="file" name="gig_cover" id="gig_cover" onChange={handleImageChange} accept="image/*" hidden />
                    </label>
                    <div className="form-group">
                        <label htmlFor="gigName">
                            <span>Gig Name</span>
                        </label>
                        <input type="text" defaultValue={gig.gigName} onChange={handleFieldChange} name="gigName" id="gigName" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="address">
                            Address
                        </label>
                        <input type="text" defaultValue={gig.address} onChange={handleFieldChange} id="address" name="address" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Frequency</label>
                        <select id="frequency" name="frequency" defaultValue={gig.frequency} onChange={handleFrequencyChange}>
                            <option value="multiple">Multiple</option>
                            <option value="once">One Time</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>
                            <span>Dates (e.g Jan 03 2020, Jan 06 2020)</span>
                        </label>
                        <input type="text" required defaultValue={gig.dates} id="dates-input" onChange={handleDatesChange} />
                    </div>
                    <div>
                        <div className="form-group">
                            <label htmlFor="time_start">Time Start</label>
                            <input type="time" id="time_start" name="time_start" defaultValue={gig.time_start} onChange={handleFieldChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="time_end">Time End</label>
                            <input type="time" id="time_end" name="time_end" defaultValue={gig.time_end} onChange={handleFieldChange} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Short description</label>
                        <input type="text" defaultValue={gig.description} id="description" name="description" onChange={handleFieldChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="setlist">Setlist (separated by commas)</label>
                        <input type="text" name="setlist" defaultValue={gig.setlist} onChange={handleSetlistChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="compensation">Compensation</label>
                        <input type="text" defaultValue={gig.compensation} id="compensation" name="compensation" onChange={handleFieldChange} />
                        <div>
                            <input type="checkbox" defaultChecked={gig.negotiable} id="negotiable" name="negotiable" onChange={handleFieldChange} />
                            <label htmlFor="negotiable">Negotiable based on experience</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="experience">Minimum Experience Needed</label>
                        <input type="number" defaultValue={gig.experience} id="experience" name="experience" onChange={handleFieldChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="performance_space_size">Performance Space</label>
                        <input type="number" required id="performance_space_size" name="performance_space_size" defaultValue={gig.performance_space_size} onChange={handleFieldChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="stage_image">
                            <span>Stage Image</span>
                            <input type="file" name="stage_image" hidden onChange={handleImageChange} id="stage_image" />
                            <div style={addGigCoverStyling(gig.stage_image_url)} ref={stageImageRef}></div>
                        </label>
                    </div>
                    <div className="tags">
                        {gig.tags!.map((tag, i) => (<div key={i} className="genre-tag">{tag}</div>))}
                        <button type="button" className="update-tag" onClick={() => setShowModal(true)}>Update</button>
                    </div>
                    <div className="controls">
                        <button onClick={handleSaveEdit} className="primary-button rounded" type="submit">Save</button>
                        <button onClick={handleGigDelete} className="outline-button rounded" type="button">Delete Gig</button>
                        <Link to={{ pathname: `${urls.VIEW_GIG}/${id}` }} className="link-button">Cancel</Link>
                    </div>
                </form> :
                <div>Gig does not exist...</div>
            }
        </>
    )
}


export default EditGig;