import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import appContext from '../appContext';
import { Config } from '../interfaces';
import urls from '../urls';
import { SearchFilterOptions, SearchSortOptions } from '../utilities';
import useTags from '../hooks/genre-tags';
import Navigation from './components/navigation';
import useTabbedNav from './components/tabbedNav';
import './search-config.scss';
import RadioButton from './components/radio-button';

const SearchConfig = () => {
    const { config, setConfig, user } = useContext(appContext);
    const [activeConfig, setActiveConfig] = useState<Config>({
        activeFilters: [...config.activeFilters],
        activeSort: config.activeSort
    })
    const { TagsComponent,selectedTags } = useTags(config.activeFilters);
    const history = useHistory();
    const isMusician = user!.type === 'musician';

    const Filter = () => {
        const options = SearchFilterOptions();

        const handleFilterSelect = (t: string) => {
            if (activeConfig.activeFilters.includes(t)) {
                setActiveConfig(prev => ({ ...prev, activeFilters: prev.activeFilters.filter(f => f !== t) }))
            } else if (t === options.none) {
                setActiveConfig(prev => ({ ...prev, activeFilters: [t] }))
            } else {
                setActiveConfig(prev => ({ ...prev, activeFilters: [...prev.activeFilters.filter(f => f !== options.none), t] }))
            }
        }

        return (
            <div>
                {
                    isMusician ?
                        <fieldset>
                            {Object.values(options).map((t, i) => {
                                return (
                                    <label key={i}>
                                        <span>{t}</span>
                                        <RadioButton onChange={() => handleFilterSelect(t)} checked={activeConfig.activeFilters.includes(t)}/>
                                    </label>
                                )
                            })}
                        </fieldset>
                        :
                        <div>
                            <TagsComponent mode="search" placeholder="Search for tags to filter by. E.g rap" />
                        </div>
                }
            </div>
        )
    }

    const Sort = () => {
        const options = Object.values(SearchSortOptions(user!.type));

        return (
            <div>
                <fieldset>
                    {options.map((t, i) => {
                        return (
                            <label key={i}>
                                <span>{t}</span>
                                <RadioButton onChange={() => setActiveConfig(prev => ({ ...prev, activeSort: t! }))} checked={t === activeConfig.activeSort}/>
                            </label>
                        )
                    })}
                </fieldset>
            </div>
        )
    }
    const { ActiveTabComponent, TabbedNav } = useTabbedNav([
        {
            component: Sort,
            name: 'Sort'
        },
        {
            component: Filter,
            name: 'Filter'
        }
    ]);
    return (
        <>
            <Navigation withShadow />
            <div className="container search-config">
                <div className="search-config__main">
                    <TabbedNav />
                    <ActiveTabComponent />
                </div>
                <button className="primary-button rounded" onClick={() => {
                    setConfig(({...activeConfig,activeFilters: [...activeConfig.activeFilters, ...(isMusician ? [] : selectedTags)]}));
                    history.push(urls.HOME)
                }}>
                    Save
                </button>
                <button className="outline-button rounded" onClick={() => history.push(urls.HOME)}>Cancel</button>
            </div>
        </>
    )
}

export default SearchConfig;