import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Tab {
    component: React.FC<any>
    name: string;
    path?: string;
}

const useTabbedNav = (tabs: Tab[], useRouter?: boolean) => {
    const [activeTab, setActiveTab] = useState(0);
    return {
        TabbedNav: () => {
            const location = useLocation();

            useEffect(() => {
                if (useRouter) {
                    let path = location.pathname;
                    let index = tabs.findIndex(tab => tab.path === path);
                    setActiveTab(index);
                }
            }, [location.pathname])

            return (
                <nav className="tabbed-nav">
                    <div>
                        {
                            tabs.map(({ name, path }, index) => <Link to={useRouter ? path! : '#'} key={index} className={`${index === activeTab ? 'active' : ''}`} onClick={() => setActiveTab(index)}>{name}</Link>)
                        }
                    </div>
                </nav>
            )
        },
        ActiveTabComponent: tabs[activeTab].component
    }
}

export default useTabbedNav;