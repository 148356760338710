import React, { useRef, useState } from 'react';
import './sign-in.scss';
import { Link, useHistory } from 'react-router-dom';
import urls from '../urls';
import { store } from 'react-notifications-component';
import { showErrorAlert, showInfoAlert } from '../utilities';
import useMediator from '../hooks/mediator';
import API from '../firebase';

const SignIn = (props: { 
    ResetsetShowModal?: () => void ,
    onlySignIn ?: boolean,
    title ?: string,
    hideModal ?: () => void;
    requiredEmail ?: string;
}) => {
    const history = useHistory();
    const formRef = useRef<HTMLFormElement>(null);
    const [isSigningIn, setSigningIn] = useState(false);
    const mediator = useMediator();
    const { title,onlySignIn,hideModal,requiredEmail } = props;

    const handleSignIn = async () => {
        let form = formRef.current;
        if (form?.checkValidity()) {
            let id;
            setSigningIn(true);
            try {
                let emailField = form?.querySelector('input[type=email]') as HTMLInputElement;
                let passwordField = form?.querySelector('input[type=password]') as HTMLInputElement;
                let email = emailField.value;
                if(onlySignIn && email !== requiredEmail!){
                    throw new Error('Email must be the same as the email to which the verification link was sent to.')
                }
                let id = showInfoAlert('Trying to sign you into your account...', 'Logging in');
                let password = passwordField.value;
                !onlySignIn ? await mediator.signUserIn(email,password) : await API.auth.signInWithEmailAndPassword(email,password);
                store.removeNotification(id);
                !onlySignIn ? history.push(urls.HOME) : hideModal!();
            } catch (e) {
                id && store.removeNotification(id);
                const message = e.message;
                showErrorAlert(message);
                setSigningIn(false);
                id && store.removeNotification(id);
            }
        }
    }

    return (
        <div id="sign-in">
            <h4>{title ? title : 'Welcome to MatchBand!'}</h4>
            <form ref={formRef} onSubmit={e => {
                e.preventDefault();
                handleSignIn();
            }}>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" required placeholder="Email" defaultValue={requiredEmail} />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" required placeholder="Password" />
                </div>
                {!onlySignIn && <button className="link-button" type="button" onClick={() => props.ResetsetShowModal!()}>Forgot Password?</button>}
                <button className="button rounded" type="submit" disabled={isSigningIn}>Sign In</button>
            </form>
            {!onlySignIn && <Link to={urls.SIGN_UP} className="link-button">New Account</Link>}
        </div>
    )
}

export default SignIn;