import React, { PropsWithChildren, useState, useEffect, ReactNode } from 'react';


const useModal = () => {
    const [showModal, setShowModal] = useState(false);
    const [content, setModalContent] = useState<ReactNode>();
    return {
        Modal: ({ onClose, onOpen, collapsible, children }: PropsWithChildren<{ collapsible?: boolean, onOpen?: () => void, onClose?: () => void }>) => {
            useEffect(() => {
                showModal && window.scrollTo({ top: 0, behavior: 'smooth'});
            }, [])

            const handleCloseModal = () => {
                onClose && onClose();
                setShowModal(false);
            }

            return (
                showModal ?
                    <div className="modal-container">
                        <div className="modal-bg" onClick={() => collapsible ? handleCloseModal() : null}>
                        </div>
                        <div className="modal-content">
                            {collapsible && <button className="close-button" onClick={handleCloseModal}><img alt="" src={require('../assets/close-icon.svg')} /></button>}
                            <div className="children">
                            {content ? content : children}
                            </div>
                        </div>
                    </div> : null
            )
        },
        setShowModal,
        setModalContent
    }
}

export default useModal;