import React, { useRef, FormEvent, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Navigation from './components/navigation';
import './password-reset.scss';
import urls from '../urls';
import API from '../firebase';
import { store } from 'react-notifications-component';
import { showErrorAlert, showInfoAlert } from '../utilities';

const PasswordReset = () => {
    const location = useLocation();
    let params = new URLSearchParams(location.search);
    let code = params.get('code');
    let formRef = useRef<HTMLFormElement>(null);
    let history = useHistory();
    const [timerID, setID] = useState<NodeJS.Timeout>();

    useEffect(() => {
        return () => timerID && clearTimeout(timerID);
    }, [timerID]);

    const isValidResetCode = () => {
        if (!code) {
            showErrorAlert('invalid reset url. check your email and follow the link to reset password');
            return false;
        }
        return true;
    }

    const extractValues = () => {
        if (formRef.current && formRef.current?.checkValidity() && isValidResetCode()) {
            let inputs = formRef.current?.querySelectorAll('input');
            let first = inputs[0];
            let second = inputs[1];
            return [first.value, second.value]
        } else {
            showErrorAlert('Passwords don\'t match.')
            return ['', '']
        }
    }
    const handlePasswordReset = async (e: FormEvent) => {
        e.preventDefault();
        let [first, second] = extractValues();
        if (first === second && first !== '') {
            try {
                let notifyID = showInfoAlert('We are resetting your password', 'Confirming password code');
                await API.auth.confirmPasswordReset(code!, first);
                store.removeNotification(notifyID);
                showInfoAlert('Your password has been reset. Redirecting your to the homepage...', 'Password reset complete')
                let id = setTimeout(() => history.push(urls.HOME), 1600);
                setID(id);
            } catch {
                showErrorAlert('Invalid reset code. Please try resetting password again.')
            }
        }
    }

    return (
        <>
            <Navigation withShadow />
            <form className="container password-reset" ref={formRef} onSubmit={handlePasswordReset}>
                <h2>Password Reset</h2>
                <div className="form-group">
                    <label htmlFor="password">New Password*</label>
                    <input type="password" required placeholder="Password" />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Confirm Password*</label>
                    <input type="password" required placeholder="Password" />
                </div>
                <button onClick={handlePasswordReset} className="rounded primary-button button">Reset</button>
            </form>
        </>
    )
}

export default PasswordReset;