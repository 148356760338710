import React, {useRef, useState, useEffect } from 'react';
import { verifyUrlUniqueness, getProfileUrl } from '../utilities';


const useUrlVerify = (defaultURL?: string,stageName?: string) => {
    const urlRef = useRef<HTMLInputElement>(null);
    
    return {
        UrlComponent: () => {
            const [url,setUrl] = useState('');

            useEffect(() => {
                if(defaultURL){
                    setUrl(defaultURL);
                }else if(stageName){
                    let url = getProfileUrl(stageName);
                    setUrl(url);
                }
            },[]);
            
            return (
                <div className="form-group">
                    <label htmlFor="profile-link">Profile Link</label>
                    <input type="text" name="url" required defaultValue={url} ref={urlRef} />
                </div>
            )
        } ,
        getUrl: () => {
            let value = urlRef.current?.value;
            let r = RegExp(`${window.location.host}/in/(\\w+)`,'g');
            if(value && r.test(value)){
                if(!verifyUrlUniqueness(value,'url')){
                    throw new Error(`This link is being used by another account. Please try again or discard your current change.`)
                }
                return value;
            } else {
                throw new Error(`Invalid profile url: must be of form "${window.location.host}/in/<unique_username>"`)
            }
        }
    }
}

export default useUrlVerify;