import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import appContext from '../../appContext';
import emailTemplates from '../../email-templates';
import API from '../../firebase';
import useDropDown from '../../hooks/dropdown';
import useMediator from '../../hooks/mediator';
import useModal from '../../hooks/modal';
import { SubmittedApplicationRecord, ApplicationRecord, Gig, MusicianUser, Slot } from '../../interfaces';
import urls from '../../urls';
import { toLocaleTime } from '../../utilities';
import "./gig-card.scss";

const GigCard = (props: {
    gig: Gig
    slot?: Slot
}) => {
    const { gig, slot } = props;
    const history = useHistory();
    const { user } = useContext(appContext);
    const asMusicianUser = (user as MusicianUser);
    const { DropDown, showDropDown } = useDropDown();
    const { Modal, setModalContent, setShowModal } = useModal();
    const [application, setApplication] = useState<ApplicationRecord>();
    const mediator = useMediator();
    const gigCoverUrl = gig.gig_cover_url ? gig.gig_cover_url : require('../../assets/image-add-bg.jpg');

    useEffect(() => {
        const loadAssociatedApplication = () => {
            let associatedApplication = asMusicianUser.applications.find(a => a.slot_id === slot?.id);
            if (associatedApplication) {
                setApplication(associatedApplication);
            }
        }

        if (user?.type === 'musician' && asMusicianUser) {
            loadAssociatedApplication();
        }

    }, [asMusicianUser, slot, user]);

    const venueDecisionMessage = () => {
        if (application?.venueDecision === 'accepted') {
            return <p>Your application was accepted! Click on the options button to review your offer</p>
        } else if (application?.venueDecision === 'undecided') {
            return <p>Awaiting venue approval</p>
        } else if (application?.venueDecision === 'waitlist') {
            return <p>You are currently on a waitlist.</p>
        } else {
            return <p>Unfortunately, you were not accepted for this slot.</p>
        }
    }

    const musicianDecisionMessage = () => {
        if (application?.musicianOfferDecision === 'rejected') {
            return <p>You declined this offer</p>
        } else if (application?.musicianOfferDecision === 'accepted') {
            return <p>Congrats on accepting this offer!</p>
        }
    }

    const statusMessage = () => {
        if (slot === undefined) {
            return <div className="st-message">
                <p>{gig.venue} posted on {(new Date(gig.date_posted!).toDateString().slice(0,10))}</p>
                <p>
                    <img src={require('./../../assets/location-icon.svg')} alt=""/>
                    {`${gig.address?.split(',').slice(1,).join(',')}.`}
                </p>
            </div>;
        };
        if (user?.type === 'musician') {
            if (!application) {
                return <p>Something went wrong.</p>
            }
            if (application?.musicianOfferDecision !== 'undecided') {
                return musicianDecisionMessage();
            } else {
                return venueDecisionMessage();
            }
        }
    }

    const deleteSomeApplicationFromGig = (filterFunction: (app: SubmittedApplicationRecord) => boolean) => {
        let updatedGigApplicants = gig.interestedApplicants!.filter(filterFunction);
        const addedNewImages = { gig_cover: false, stage_image: false };
        API.updateGig(addedNewImages, { id: gig.id, gig_cover_url: gig.gig_cover_url, stage_image_url: gig.stage_image_url, interestedApplicants: updatedGigApplicants } as Gig);
    }

    const updateSomeGigApplicationInGig = (mapFunction: (app: SubmittedApplicationRecord) => SubmittedApplicationRecord) => {
        let updatedGigApplications = gig.interestedApplicants!.map(mapFunction);
        return updatedGigApplications;
    }

    const updateAssociatedGigSlot = (mapFunction: (slot: Slot) => Slot) => {
        let updatedSlots = gig.slots!.map(mapFunction);
        return updatedSlots;
    }

    const handleOfferReview = (decision: 'accepted' | 'rejected') => {
        /**
         * Rejecting an offer should NOT delete a user's application
         */
        let subject, template_name;
        let date = (new Date(slot!.date)).toDateString();
        let template_content: any = {
            musicianName: asMusicianUser.stageName,
            gigName: gig.gigName,
            date
        };

        let assignedTo = slot!.assignedTo;
        if (decision === 'rejected') {
            // send notification email to venue and update application record
            subject = `${asMusicianUser.stageName} rejected your offer for ${gig.gigName} on ${date}`
            template_name = emailTemplates.offerRejection;
            assignedTo = "";
        } else {
            subject = `${asMusicianUser.stageName} accepted your offer for ${gig.gigName} on ${date}!`
            template_name = emailTemplates.offerAcceptance;
            template_content.email = asMusicianUser.email;
        }
        const msg = {
            template_id: template_name,
            personalizations: [
                {
                    to: [{
                        email: gig.email!
                    }],
                    subject,
                    dynamic_template_data: template_content
                }
            ]
        }
        API.sendEmail(msg);
        batchGigUpdates(decision, assignedTo);
        const updateMusicianDecisionForAssociatedSlot = (app: ApplicationRecord) => app.slot_id === slot?.id ? { ...app, musicianOfferDecision: decision } : app;
        let updatedApplications: ApplicationRecord[] = asMusicianUser.applications.map(updateMusicianDecisionForAssociatedSlot);
        mediator.updateUserProfile({ applications: updatedApplications }, user!.uid);
        setShowModal(false);
    };

    const batchGigUpdates = (decision: 'accepted' | 'rejected', assignedTo: string) => {
        const updateSlotOfferAccepted = (slot_: Slot) => slot_.id === slot!.id ? { ...slot_, assignedTo, offer_accepted: decision === 'accepted' ? true : false } : slot_;
        let updatedSlots = updateAssociatedGigSlot(updateSlotOfferAccepted);
        const updateSubmittedApplicationRecordForAssociatedSlot = (app: SubmittedApplicationRecord) => app.slot_id === slot?.id && app.musicianId === asMusicianUser.uid ? { ...app, musicianOfferDecision: decision } : app;
        let updatedGigApplications = updateSomeGigApplicationInGig(updateSubmittedApplicationRecordForAssociatedSlot);
        API.updateGig({ gig_cover: false, stage_image: false }, { ...gig, slots: updatedSlots, interestedApplicants: updatedGigApplications });
    }

    const handleAppWithdraw = () => {
        if (slot?.assignedTo === user?.uid) {
            handleOfferReview('rejected');
        }
        let unwithdrawnApplications = asMusicianUser.applications.filter(app => app.slot_id !== application?.slot_id);
        mediator.updateUserProfile({ applications: unwithdrawnApplications }, user!.uid);
        const deleteThisApplication = (app: SubmittedApplicationRecord) => !(app.musicianId === user!.uid && app.slot_id === slot!.id);
        deleteSomeApplicationFromGig(deleteThisApplication);
        setShowModal(false);
    }

    const showOfferReviewModal = () => {
        setModalContent(() => {
            return (
                <div>
                    <p>Please confirm if you intend on proceeding with this gig.</p>
                    <div className="controls">
                        <button className="link-button" onClick={() => handleOfferReview('accepted')}>Accept</button>
                        <button className="link-button" onClick={() => handleOfferReview('rejected')}>Decline</button>
                    </div>
                </div>
            )
        });
        setShowModal(true);
    }

    const showApplicationWithdrawModal = () => {
        const applicantHasOfferWarning = application?.venueDecision === 'accepted' ? <p>This action will automatically rescind your offer.</p> : null;
        setModalContent(() => {
            return (
                <div className="application-withdraw">
                    <p>Are you sure you want to withdraw your application?</p>
                    {applicantHasOfferWarning}
                    <div className="controls">
                        <button onClick={handleAppWithdraw} className="link-button">Withdraw</button>
                        <button onClick={() => setShowModal(false)} className="link-button">Cancel</button>
                    </div>
                </div>
            )
        });
        setShowModal(true);
    }

    const showApplicationOptions = () => {
        return slot ?
            <div className="options">
                <button onClick={() => showDropDown(prev => !prev)} className="options__button"><img src={require('../../assets/options-icon.svg')} alt="options" /></button>
                <DropDown>
                    <ul>
                        {slot.assignedTo === user?.uid && application?.musicianOfferDecision === 'undecided' ? <button onClick={showOfferReviewModal}>Accept/Decline Offer</button> : null}
                        <button onClick={showApplicationWithdrawModal}>Withdraw application</button>
                    </ul>
                </DropDown>
            </div> :
            null
    }

    return (
        <div className="ad-card" onClick={() => {
            !slot && history.push(urls.VIEW_GIG + `/${gig.id}`);
        }}>
            <Modal collapsible></Modal>
            <div className="ad-card__date text-12">
                <p>{slot ? `${slot.date},${toLocaleTime(gig.time_start!)} - ${toLocaleTime(gig.time_end!)}` : `Starting ${gig.slots![0].date.slice(0, 10)}`}</p>
            </div>
            <img src={gigCoverUrl} className="ad-card__cover" alt="" />
            <div className="ad-card__description">
                <div className="status-box">
                    {statusMessage()}
                    {showApplicationOptions()}
                </div>
                <h3>{gig.gigName}</h3>
                <p>{gig.description}</p>
                <ul className="ad-card__tags">
                    {gig.tags && gig.tags.map((tag, i) => <li key={i} className="genre-tag">{tag}</li>)}
                </ul>
            </div>
            {user?.type === 'venue' && gig.hasUnreviewedApps ? <p className="new-applications-banner">New applications need review</p> : null}
        </div>
    )
}

export default GigCard;