import React, { useRef, FormEvent } from 'react';
import API from '../firebase';
import Navigation from './components/navigation';
import './new-account.scss';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import urls from '../urls';
import { store } from 'react-notifications-component';
import { showErrorAlert, showInfoAlert } from '../utilities';

const NewAccount = (props: RouteComponentProps<{user_type: string}>) => {
    const formRef = useRef<HTMLFormElement>(null);
    const userType = props.match.params.user_type;
    const history = useHistory();

    const validateForm = async (e: FormEvent) => {
        e.preventDefault();
        if (formRef.current?.checkValidity()) {
            let email = formRef.current.querySelector('input[type=email]') as HTMLInputElement;
            let password = formRef.current.querySelector('input[type=password]') as HTMLInputElement;
            try {
                let notifyID = showInfoAlert('Give us a few seconds to create your account','We are creating an account for you...');
                await API.createNewUser(email.value, password.value,`${urls.HOST}${urls.CREATE_PROFILE}?userType=${userType}&email=${email.value}`);
                store.removeNotification(notifyID);
                showInfoAlert('Follow the link sent to your email to complete your MatchBand profile','Welcome to MatchBand!',5000)
                history.push(urls.HOME);
            } catch(e){
                const message = e.message;
                showErrorAlert(message);
            }
        } else {
            showErrorAlert('Please make sure you have supplied a valid email, and your password is at least 6 characters long with at least 1 letter and 1 number.')
        }
    }

    return (
        <>
            <Navigation noProfile withShadow />
            <div className="container new-account">
                <form ref={formRef} onSubmit={validateForm}>
                    <h2>Create Account</h2>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" required placeholder="Email" />
                    </div>
                    {userType === 'musician' && <p>
                        Your email will <b>NOT</b> be visible to venues without further permission.
                    </p>}
                    <div className="form-group">
                        <label htmlFor="password">Password (6 characters min, 1 letter, 1 number required)</label>
                        <input type="password" min="6" pattern="\w+\d+.*" required placeholder="Password" />
                    </div>
                </form>
                <button className="button rounded primary-button" onClick={validateForm}>Submit</button>
            </div>
        </>
    )
}

export default NewAccount;