import { useCallback, useContext, useEffect, useState } from 'react';
import appContext from '../appContext';
import { GigData, MusicianUser, Slot, VenueUser } from '../interfaces';
import { SearchFilterOptions, SearchSortOptions } from '../utilities';
import LatLon from 'geodesy/latlon-spherical';

type contentType = GigData[] | MusicianUser[];

const useSearch = (content_: contentType, searchableProps: string[], ignoreConfig?: boolean) => {
    const [content, setSearchContent] = useState<contentType>(content_);
    const [results, setResults] = useState<contentType>(content);
    const { config, user } = useContext(appContext);
    const [query, setQuery] = useState('');

    const computeDistanceToSource = (a: firebase.firestore.GeoPoint, b: firebase.firestore.GeoPoint, source: firebase.firestore.GeoPoint) => {
        let p1 = new LatLon(a.latitude, a.longitude);
        let p2 = new LatLon(b.latitude, b.longitude);
        let source_LatLon = new LatLon(source.latitude, source.longitude);
        let p1_dist = p1.distanceTo(source_LatLon);
        let p2_dist = p2.distanceTo(source_LatLon);
        return [p1_dist, p2_dist]
    }

    const searchFunction = useCallback((query: string) => {
        const sortGigsFunction = (a: GigData, b: GigData) => {
            const options = SearchSortOptions('musician');
            const musicianUser = user as MusicianUser;

            switch (config.activeSort) {
                case options.experience:
                    return +a.experience - +b.experience;
                case options.relevancy:
                    const AlowerCaseTags = a.tags!.map(t => t.toLowerCase());
                    const BlowerCaseTags = b.tags!.map(t => t.toLowerCase())
                    let a_int = musicianUser.tags!.filter(t => AlowerCaseTags.includes(t.toLowerCase()));
                    let b_int = musicianUser.tags!.filter(t => BlowerCaseTags.includes(t.toLowerCase()));
                    return b_int.length - a_int.length
                case options.length:
                    let a_len = Date.parse(`01/01/2011 ${a.time_start}`) - Date.parse(`01/01/2011 ${a.time_end}`);
                    let b_len = Date.parse(`01/01/2011 ${b.time_start}`) - Date.parse(`01/01/2011 ${b.time_end}`);
                    return a_len - b_len;
                case options.compensation:
                    let m1 = a.compensation.match(/\d+/);
                    let m2 = b.compensation.match(/\d+/);
                    if (!m1 || !m2) {
                        return 0;
                    }
                    let b_comp = m2[0];
                    let a_comp = m1[0];
                    return +b_comp - +a_comp;
                case options.location:
                    let source = musicianUser.location.coordinate!;
                    let [p1_dist, p2_dist] = computeDistanceToSource(a.location.coordinate!, b.location.coordinate!, source)
                    return (p2_dist - p1_dist)
                case options.coming_soon:
                    const f = (s: Slot) => new Date() <= new Date(s.date);
                    const AearliestApplicableSlot = a.slots.find(f);
                    const BearliestApplicableSlot = b.slots.find(f);
                    if (!AearliestApplicableSlot) return 1;
                    if (!BearliestApplicableSlot) return -1;
                    let a_earliest_start = AearliestApplicableSlot.date;
                    let b_earliest_start = BearliestApplicableSlot.date;
                    return Date.parse(a_earliest_start) - Date.parse(b_earliest_start)
                default:
                    return Date.parse(b.date_posted) - Date.parse(a.date_posted);
            }
        }

        const sortMusiciansFunction = (a: MusicianUser, b: MusicianUser) => {
            if (config.activeSort === 'None') {
                return 0;
            }
            if (config.activeSort === 'location') {
                return +a.experience! - +b.experience!
            }
            return +b.experience! - +a.experience!
        }

        const isWithinRange = (source: LatLon, destination: LatLon) => {
            const threshold = 160934;
            return source.distanceTo(destination) <= threshold;
        }

        const isGigWithinRange = (gig: GigData) => {
            //filter out all gigs with locations beyong 160934m (100mi)
            if (user?.type === 'venue') return true;
            const asMusician = user as MusicianUser;
            let a = gig.location.coordinate?.latitude!;
            let b = gig.location.coordinate?.longitude!;
            let p1 = new LatLon(a, b);
            let source = new LatLon(asMusician.location.coordinate?.latitude!, asMusician.location.coordinate?.longitude!);
            return isWithinRange(source, p1);
        }

        const isMusicianWithinRange = (musician: MusicianUser) => {
            let a = musician.location.coordinate?.latitude!;
            let b = musician.location.coordinate?.longitude!;
            const asVenue = user as VenueUser;
            let p1 = new LatLon(a, b);
            let source = new LatLon(asVenue.location.coordinate!.latitude, asVenue.location.coordinate!.longitude);
            return isWithinRange(source, p1)
        }

        const filterGigsFunction = (gig: GigData) => {
            const options = SearchFilterOptions();
            let f = config.activeFilters;
            let values: boolean[] = [isGigWithinRange(gig)];
            let musician = user as MusicianUser;
            f.forEach((filter) => {
                let start, end, days;
                switch (filter) {
                    case options.daytime:
                        start = Date.parse(`01/01/2011 ${gig.time_start}`) >= Date.parse(`01/01/2011 09:00`);
                        end = Date.parse(`01/01/2011 ${gig.time_start}`) <= Date.parse(`01/01/2011 16:00`);
                        values.push(start && end);
                        break;
                    case options.night:
                        start = Date.parse(`01/01/2011 ${gig.time_start}`) >= Date.parse(`01/01/2011 16:00`);
                        end = Date.parse(`01/01/2011 ${gig.time_start}`) <= Date.parse(`01/01/2011 00:00`);
                        values.push(start && end);
                        break;
                    case options.late:
                        start = Date.parse(`01/01/2011 ${gig.time_start}`) >= Date.parse(`01/01/2011 00:00`);
                        end = Date.parse(`01/01/2011 ${gig.time_start}`) <= Date.parse(`01/01/2011 09:00`);
                        values.push(start && end);
                        break;
                    case options.weekdays:
                        days = gig.dates.map(d => new Date(d).getDay()).filter(d => d > 0 && d <= 5);
                        values.push(days.length > 0);
                        break;
                    case options.weekends:
                        days = gig.dates.map(d => new Date(d).getDay()).filter(d => d === 0 || d === 6);
                        values.push(days.length > 0);
                        break;
                    case options.my_style:
                        const lowerCaseMusicianTags = musician.tags!.map(t => t.toLowerCase())
                        let matches = gig.tags.map(t => lowerCaseMusicianTags.includes(t.toLowerCase()));
                        values.push(matches.some(t => t === true));
                        break;
                    case options.none:
                        values.push(true);
                        break;
                    default:
                        break;
                }
            })
            return values.every(t => t === true);
        }

        const filterMusiciansFunction = (musician: MusicianUser) => {
            return isMusicianWithinRange(musician) && (config.activeFilters.length === 0 || musician.tags!.map(tag => config.activeFilters.includes(tag)).some(v => v === true));
        }
        const filterFunction = () => {
            if (ignoreConfig) {
                return () => true;
            }
            return (user && user.type === 'musician') ? filterGigsFunction : filterMusiciansFunction;
        };

        const sortFunction = () => {
            if (ignoreConfig) {
                return (a: GigData, b: GigData) => Date.parse(b.date_posted) - Date.parse(a.date_posted);;
            }
            return user && user.type === 'musician' ? sortGigsFunction : sortMusiciansFunction;
        };

        let results_: contentType = (content as any).filter((a: any) => {
            let f = filterFunction();
            let containsQuery = searchableProps.some(q => a[q].toLowerCase().includes(query.toLowerCase()));
            return f(a) && containsQuery;
        }).sort(sortFunction());
        setResults(results_);
    }, [content, config, user])

    useEffect(() => {
        if (user) {
            searchFunction(query.toLocaleLowerCase());
        }
    }, [query, user, searchFunction]);

    useEffect(() => {
        if (content) {
            setResults(content);
        }
        if (user) {
            searchFunction(query.toLocaleLowerCase());
        }
    }, [content, user, searchFunction, setResults, query])


    return {
        results,
        setSearchContent,
        query,
        setQuery
    }
}

export default useSearch;