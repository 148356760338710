import React, { useContext, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import appContext from '../../appContext';
import emailTemplates from '../../email-templates';
import API from '../../firebase';
import { VenueUser } from '../../interfaces';
import urls from '../../urls';
import { showErrorAlert, showInfoAlert } from '../../utilities';
import Navigation from '../components/navigation';


const SendMessage = () => {
    const { state } = useLocation() as any;
    const { user } = useContext(appContext);
    const venueUser = user as VenueUser;
    const messageBoxRef = useRef<HTMLFormElement>(null);
    const history = useHistory();

    const handleMessageSend = async () => {
        let body = messageBoxRef.current?.querySelector('.message');
        if (messageBoxRef.current?.checkValidity() && body?.innerHTML) {
            let subject = messageBoxRef.current.querySelector('input');
            try {
                const msg = {
                    template_id: emailTemplates.musicianInvite,
                    personalizations: [
                        {
                            subject: subject!.value,
                            to: [{
                                email: state.email
                            }],
                            dynamic_template_data: {
                                venue_name: venueUser.name!,
                                body: body.innerHTML
                            }
                        }
                    ]
                }
                await API.sendEmail(msg);
                showInfoAlert('Message sent', 'Success')
                history.push(urls.HOME);
            } catch (e) {
                const { message } = e;
                showErrorAlert(message);
            }
        }
    }

    return (
        <div>
            <>
                <Navigation withShadow />
                {
                    state && user?.gig_ids.length ?
                        <div className="container send-message">
                            <h2>to: {state.stage_name}</h2>
                            <p>{state.location.split(',').slice(1,)}</p>
                            <form ref={messageBoxRef} className="message-box">
                                <input type="text" required defaultValue="We Want Musicians!" id="" />
                                <div className="message" contentEditable>
                                    <p>Hi {state.stage_name},</p>
                                    <br/>
                                    <p>{venueUser.name} is looking for performers for upcoming gigs and I recently came across your MatchBand profile. Here is a link to our most recent gig:</p>
                                    <a href={`${window.location.origin}${urls.VIEW_GIG}/${user.gig_ids[user.gig_ids.length - 1]}`}>{window.location.origin}{urls.VIEW_GIG}/{user.gig_ids[user.gig_ids.length - 1]}</a>
                                    <p>If you are interested, feel free to reach out to me at {user?.email}. I am looking forward to hearing from you!</p>
                                    <br/>
                                    <p>Thanks,</p>
                                    <p>{venueUser.name}</p>
                                </div>
                            </form>
                            <button className="primary-button rounded" onClick={handleMessageSend}>Send</button>
                        </div>
                        :
                        <p style={{ 'textAlign': 'center'}}>You must have at least one gig published before you can send an invite to a musician.</p>
                }
            </>
        </div>
    )
}

export default SendMessage;