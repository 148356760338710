import React, { useContext, useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { Link, RouteComponentProps } from 'react-router-dom';
import appContext from '../appContext';
import API from '../firebase';
import { Gig, Slot } from '../interfaces';
import urls from '../urls';
import Navigation from './components/navigation';
import SlotCard from './components/slot';
import './view-gig.scss';

const ViewGig = (props: RouteComponentProps<{}>) => {
    let { id } = props.match.params as any;
    const { user } = useContext(appContext);
    const [gig, setGig] = useState<Gig>();
    const gigCoverUrl = gig?.gig_cover_url ? gig.gig_cover_url : require('../assets/image-add-bg.jpg');

    useEffect(() => {
        if (user && !gig) {
            API.collectGigs([id]).then(gigs_ => {
                setGig(gigs_[0]);
            })
        }
    }, [user, gig, id])

    const hasApplied = (slot: Slot) => {
        if(user?.type === 'venue'){
            return;
        }
        return user?.applications?.some(a => a.slot_id === slot.id);
    }

    const computeTimeInterval = () => {
        let start = gig!.time_start!.split(':');
        let end = gig!.time_end!.split(':');
        let startHr = +start[0];
        let endHr = +end[0];
        let startMin = +start[1];
        let endMin = +end[1];
        let hours,minutes;
        if(startHr > endHr){
            hours = startHr - endHr - 12;
        } else {
            hours = startHr - endHr;
        }
        minutes = Math.abs(startMin - endMin);
        return `${Math.abs(hours)}${minutes ? `.${minutes}` : ''} Hours`;
    }

    return (
        <>
            <Navigation withShadow />
            {
                gig ?
                    <>
                        {gig.interestedApplicants!.length && user?.type === 'venue' ? <div className="unreviewed-alert-banner text-12">
                            Click here to review applications. <Link to={`${urls.REVIEW_APPS}/${id}`}>Review now</Link>
                            </div> : null}
                        <div className="view-gig__banner">
                            <img src={gigCoverUrl} alt="" />
                        </div>
                        <div className="container view-gig">
                            <div className="view-gig__details">
                                <h2>{gig.gigName}</h2>
                                <p>{gig.venue}</p>
                                <p><u>{gig.address}</u></p>
                                <p id="gig-description">{gig.description}</p>
                                <div className="tags">{gig.tags && gig.tags.map((tag, i) => <div key={i} className="genre-tag">{tag}</div>)}</div>
                            </div>
                            {user?.type === 'venue' && <Link className="view-gig__edit-link" to={urls.EDIT_GIG + `/${gig.id}`}>Edit Gig</Link>}
                            <div className="view-gig__times">
                                <h3>Time Slots</h3>
                                <div className="view-gig__slots">
                                    {gig.slots?.map((slot, i) => !slot.offer_accepted ? <SlotCard key={i} disabled={hasApplied(slot)} {...{ slot, gig_id: gig.id, time_start: gig.time_start!, time_end: gig.time_end!, user_type: user!.type }} /> : null)}
                                </div>
                            </div>
                            <div className="view-gig__info">
                                <h3>Details</h3>
                                <div className="detail">
                                    <p>Gig Length</p>
                                    <p>{computeTimeInterval()}</p>
                                </div>
                                <div className="detail">
                                    <p>Setlist</p>
                                    <p id="setlist">{gig.setlist?.length ? gig.setlist.join(',') : 'Performer\'s choice'}</p>
                                </div>
                                <div className="detail">
                                    <p>Compensation</p>
                                    <p>{gig.compensation}</p>
                                </div>
                                <div className="detail">
                                    <p>Experience Preferred</p>
                                    <p>at least {gig.experience} years</p>
                                </div>
                                <div className="detail">
                                    <p>Equipment provided</p>
                                    <p>{gig.equipment}</p>
                                </div>
                                <div className="detail">
                                    <p>Performance Space</p>
                                    <p>can fit {gig.performance_space_size} people max.</p>
                                </div>
                                <div className="stage-image">
                                    <img style={{ 'width': '100%' }} src={gig.stage_image_url ? gig.stage_image_url : require('../assets/image-add-bg.jpg')} alt="" />
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <Loader type="TailSpin" color="#E51968" className="loader"/>
            }
        </>
    )
}

export default ViewGig;