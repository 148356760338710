import { useContext } from 'react';
import appContext from '../appContext';
import API from '../firebase';
import { MusicianProfileData, VenueProfileData, User, GigData, Gig } from '../interfaces';

const useMediator = () => {
    const { setUser, user, setConfig } = useContext(appContext);

    const signUserIn = async (email: string, password: string) => {
        const user = await API.signUserIn(email, password);
        setUser(user as User);
    }

    const signOut = () => {
        API.signOut();
        setUser(null);
        setConfig({
            activeFilters: [],
            activeSort: 'None'
        })
    }

    const updateUserProfile = (data: MusicianProfileData | VenueProfileData, id: string) => {
        API.updateUserProfile(data, id);
        if (user?.uid === id) {
            setUser({ ...user, ...data } as User)
        }
    }

    const setUserProfile = (data: MusicianProfileData | VenueProfileData, id: string) => {
        API.setUserProfile(data,id);
        setUser(data as User)
    }

    const publishGig = async (gig: GigData) => {
        let id = await API.publishGig(gig);
        updateUserProfile({ gig_ids: [...user!.gig_ids, id] },user!.uid);
    }

    const deleteGig = async (gig: Gig) => {
        API.deleteGig(gig);
        updateUserProfile({ gig_ids: user!.gig_ids.filter(id => id !== gig.id)},user!.uid)
    }

    const updateEmail = async (email: string) => {
        await API.updateUserEmail(email);
        setUser({...user, email } as User)
    }

    return {
        deleteGig,
        publishGig,
        signOut,
        signUserIn,
        setUserProfile,
        updateEmail,
        updateUserProfile
    }
}

export default useMediator;