import React from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import './loading.scss';

export default () => {
    return (
        <div className="loader-container">
            <p>Getting stuff ready....</p>
            <Loader type="Grid" color="#FFFFFF"/>
        </div>
    )
}