import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import appContext from '../../appContext';
import API from '../../firebase';
import useSearch from '../../hooks/search';
import { Gig, GigData } from '../../interfaces';
import GigCard from './gig-card';
import Search from './searchUI';

const GigsList = () => {
    const { user } = useContext(appContext);
    const { setSearchContent, setQuery, query, results } = useSearch([],["address", "gigName", "venue"]);
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        let isCancelled = false;
        if(!isCancelled && user){
            API.collectGigs([],false).then(all_gigs => {
                !isCancelled && setSearchContent(all_gigs as GigData[]);
            }).finally(() => !isCancelled && setLoading(false));
        }

        return () => { isCancelled = true };
    }, [setSearchContent,user])

    const renderGigsList = () => {
        if(loading){
            return <Loader type="TailSpin" className="loader" color="#E51968" />
        }
        if(!results.length){
            return <p>Nothing to show</p>
        }
        return (results as Gig[]).map((gig, i) => <GigCard key={i} gig={gig} />);
    }
    return (
        <section className="gigs-list">
            <Search {...{ query, setQuery }} placeholder="Search by location, gig name, venue" />
            {renderGigsList()}
        </section>
    )
}

export default GigsList;