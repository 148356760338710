import firebase from 'firebase';
import { User, GigData, Gig, MusicianProfileData, VenueProfileData, AuthUser } from './interfaces';
import { compressImage, getPathFromDownloadURL } from './utilities';
import uuid from 'react-uuid';
import emailTemplates from './email-templates';

class Service {
    private config: object;
    private db: firebase.firestore.Firestore;
    public auth: firebase.auth.Auth;
    private storage: firebase.storage.Storage;
    private userEmail: string | null;
    private functions: firebase.functions.Functions;

    constructor() {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            //dev
            this.config = require('./firebase-config.json');
        } else {
            this.config = {
                apiKey: process.env.REACT_APP_API_KEY,
                authDomain: process.env.REACT_APP_AUTH_DOMAIN,
                databaseURL: process.env.REACT_APP_DATABASE_URL,
                projectId: process.env.REACT_APP_PROJECT_ID,
                storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
                messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
                appId: process.env.REACT_APP_APP_ID,
                measurementId: process.env.REACT_APP_MEASUREMENT_ID
            };
        }
        firebase.initializeApp(this.config);
        this.db = firebase.firestore();
        this.storage = firebase.storage();
        this.auth = firebase.auth();
        this.userEmail = localStorage.getItem('userEmail');
        this.functions = firebase.functions();
    }

    public async updateUserEmail(email: string) {
        this.auth.currentUser?.updateEmail(email);
    }

    public async sendEmail(msg: {
        template_id: string;
        personalizations: object[]
    }) {
        const sendGrid = this.functions.httpsCallable('sendEmail');
        const { data } = await sendGrid(msg);
        return data;
    }

    public async createNewUser(email: string, password: string, continueURL: string) {
        const createUser = this.functions.httpsCallable('createUser');
        let { data } = await createUser({
            email,
            password,
            continueURL
        }) as any;
        return (await this.sendEmail({
            template_id: emailTemplates.verifyEmail,
            personalizations: [
                {
                    subject: 'Verify and Complete your Matchband Account',
                    to: [{
                        email
                    }],
                    dynamic_template_data: {
                        link: data as string
                    }
                }
            ]
        }));
    }

    public async signUserIn(email: string, password: string) {
        this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        let { user } = await this.auth.signInWithEmailAndPassword(email, password);
        let userInfo = (await this.db.doc(`/users/${user?.uid}`).get()).data() as User;
        if(!userInfo){
            throw new Error('Please verify your email and complete your profile via the link sent to your email during sign-up. You will not be able to access your account until this is done.')
        }
        localStorage.setItem('userEmail', user!.email!);
        return { ...user, ...userInfo };
    }

    public signOut() {
        localStorage.setItem('userEmail', '');
        this.userEmail = '';
        return this.auth.signOut();
    }

    public async getCurrentUser() {
        let user: AuthUser;
        if (this.auth.currentUser) {
            user = this.auth.currentUser as AuthUser
        } else if (this.userEmail) {
            user = await this.getSpecifiedUser(this.userEmail);
        } else {
            return null;
        }
        let user_data = (await this.db.doc(`/users/${user.uid}`).get()).data() as User;
        return { ...user, ...user_data } as User;
    }

    public async getSpecifiedUser(email: string) {
        const getUser = this.functions.httpsCallable('getUser');
        let { data } = await getUser(email) as any;
        return data;
    }

    public async sendPasswordResetLink(email: string) {
        const passwordReset = this.functions.httpsCallable('sendPasswordReset');
        let { data } = await passwordReset(email) as any;

        return (await this.sendEmail({
            template_id: emailTemplates.passwordReset,
            personalizations: [
                {
                    subject: 'Reset your account password',
                    to: [{
                        email
                    }],
                    dynamic_template_data: {
                        email,
                        link: data as string
                    }
                }
            ]
        }));
    }

    public async updateUserProfile(data: MusicianProfileData | VenueProfileData, id: string) {
        this.db.collection('/users').doc(id).update(data);
    }

    public async setUserProfile(data: MusicianProfileData | VenueProfileData, id: string) {
        this.db.collection('/users').doc(id).set(data);
    }

    public async fetchUserProfiles() {
        let docs = (await this.db.collection('/users').get()).docs;
        return docs.map(item => item.data());
    }

    public async saveImage(image: File, folder: string) {
        let root = this.storage.ref();
        let ref = root.child(`${folder}/${image.name}`);
        await ref.put(await compressImage(image));
        return ref.getDownloadURL();
    }

    public deleteMedia(path: string) {
        let root = this.storage.ref();
        let ref = root.child(path);
        return ref.delete();
    }

    public async collectGigs(gig_ids?: string[],useOnlySpecified = true) {
        const all_gigs = (await this.db.collection('gigs').get()).docs;
        const map_ = (gig: any) => ({ id: gig.id, ...gig.data() } as Gig);
        if (useOnlySpecified) {
            return (await this.db.collection('gigs').where('id', 'in', gig_ids).get()).docs.map(map_);
        }
        return all_gigs.map(map_);
    }

    public async publishGig(gig: GigData) {
        const { stage_image, gig_cover, ...rest } = gig;
        let id: string = uuid();
        let gig_cover_url = gig_cover ? await this.saveImage(gig_cover, `gigs/${id}/cover`) : '';
        let stage_image_url = stage_image ? await this.saveImage(stage_image, `gigs/${id}/stage_image`) : '';
        let item = {
            id,
            ...rest,
            gig_cover_url,
            stage_image_url
        }
        await this.db.collection('gigs').doc(id).set(item);
        return id;
    }

    public async updateGig(addedNewImages: { gig_cover: boolean, stage_image: boolean }, gig: Gig) {
        const { stage_image, gig_cover, ...rest } = gig;
        let [new_gig_cover_url, new_stage_image_url] = await Promise.all(Object.entries(addedNewImages).map(async (v) => {
            const [prop, changed] = v;
            let defaultDownloadURL = prop === 'gig_cover' ? rest.gig_cover_url : rest.stage_image_url
            if (changed) {
                let p = prop === 'gig_cover' ? gig_cover : stage_image;
                if (defaultDownloadURL) {
                    let path = getPathFromDownloadURL(defaultDownloadURL, 'gigs');
                    this.deleteMedia(path);
                }
                return p ? await this.saveImage(p, `gigs/${gig.id}/${prop === 'gig_cover' ? 'cover' : 'stage_image'}`) : null;
            }
            return defaultDownloadURL;
        }));
        let updatedGig = {
            ...rest,
            gig_cover_url: new_gig_cover_url,
            stage_image_url: new_stage_image_url
        }
        await this.db.collection('gigs').doc(gig.id).update(updatedGig);
    }

    public async deleteGig(gig: Gig) {
        let cover_path = getPathFromDownloadURL(gig.gig_cover_url, 'gigs');
        let stage_image = getPathFromDownloadURL(gig.stage_image_url, 'gigs');
        if (cover_path)
            this.deleteMedia(cover_path)
        if (stage_image)
            this.deleteMedia(stage_image)
        await this.db.collection('gigs').doc(gig.id).delete();
    }

    public async fetchUser(id: string) {
        return (await this.db.collection(`users`).doc(id).get()).data();
    }

    public async fetchUserWithProperty(property: string, value: string) {
        return (await this.db.collection('users').where(property, '==', value).get()).docs.map(d => d.data());
    }
}

const API = new Service();
export default API;
