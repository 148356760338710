import React, { useContext, useState, useRef } from 'react';
import Navigation from './components/navigation';
import appContext from '../appContext';
import './profile.scss';
import { MusicianUser, Media, MusicianProfileData, VenueUser } from '../interfaces';
import { Link } from 'react-router-dom';
import urls from '../urls';
import useModal from '../hooks/modal';
import API from '../firebase';
import { getLocationCoords, getPathFromDownloadURL, getYouTubeEmbedUrl, showErrorAlert, showInfoAlert } from '../utilities';
import { store } from 'react-notifications-component';
import Account from './account';
import useMediator from '../hooks/mediator';

const MusicianProfile = () => {
    let { user } = useContext(appContext);
    let musicianData = user as MusicianUser;
    let { Modal, setShowModal } = useModal();
    const [media, setMedia] = useState<{
        file: File | null,
        caption: string;
    }>({
        file: null,
        caption: ''
    });
    const [showVideoURLInput, setShowInput] = useState(false);
    const videoRef = useRef<HTMLInputElement>(null);
    const captionRef = useRef<HTMLInputElement>(null);
    const mediator = useMediator();

    const handleMediaDataExtractionAndUpload = async () => {
        let mediaData: Media;
        let caption = captionRef.current?.value as string;

        if (media.file) {
            setShowModal(false);
            let mediaURL = await API.saveImage(media.file, 'media');
            mediaData = {
                caption,
                url: mediaURL,
                type: 'image'
            }
        } else if (videoRef.current?.checkValidity()) {
            mediaData = {
                caption,
                url: videoRef.current.value,
                type: 'video'
            }
            setShowModal(false);
        } else {
            throw new Error('Invalid Input');
        }
        return mediaData;
    }

    const updateUserProfile = (mediaData: Media) => {
        let new_data = { media: [mediaData, ...musicianData.media] } as MusicianProfileData;
        mediator.updateUserProfile(new_data, user!.uid);
    }

    const handleMediaUpload = async () => {
        try {
            if (musicianData.media.length === 5) {
                throw new Error('You can upload a maximum of 5 items to your profile.')
            }
            let notifyProcessId = showInfoAlert('Uploading...', 'We are uploading your media content', 3000);
            let mediaData = await handleMediaDataExtractionAndUpload();
            updateUserProfile(mediaData);
            store.removeNotification(notifyProcessId);
            showInfoAlert('Upload successful', 'Done');
        } catch (e) {
            setShowModal(false);
            const message = e.message;
            showErrorAlert(message)
        }
    }

    const saveMedia = (file: File | null) => {
        if (file !== null) {
            setMedia(prev => ({ ...prev, file }));
        }
    }

    const deleteMedia = async (index: number, url: string) => {
        try {
            let confirmDelete = window.confirm('Are you sure you want to delete this?');
            if (confirmDelete) {
                let path = getPathFromDownloadURL(url, 'media');
                path && await API.deleteMedia(path);
                let new_data = { media: musicianData.media.filter((_, i) => i !== index) } as MusicianProfileData
                mediator.updateUserProfile(new_data, user!.uid);
            }
        } catch (e) {
            const message = e.message;
            showErrorAlert(message);
        }
    }

    const renderMedia = () => {
        return musicianData.media?.map((media, i) => (
            <div className="media-card" key={i}>
                {
                    media.type === 'video' ?
                        <iframe width="560" title={i.toString()} height="315" src={getYouTubeEmbedUrl(media.url)} allowFullScreen></iframe> :
                        <img src={media.url} alt={media.caption} />
                }
                <div>
                    <p>{media.caption}</p>
                    <button className="link-button" onClick={() => deleteMedia(i, media.url)}>Delete</button>
                </div>
            </div>
        ))
    }

    const renderMediaInput = () => {
        if (showVideoURLInput) {
            return <div>
                <div className="form-group">
                    <label htmlFor="">Youtube link</label>
                    <input ref={videoRef} type="url" required />
                </div>
            </div>
        }
    }

    const showCaptionInput = () => {
        return (showVideoURLInput || media.file) && <div className="form-group">
            <label>
                <span>Caption</span>
            </label>
            <input type="text" autoFocus ref={captionRef} />
        </div>
    }

    const renderUploadOptions = () => {
        return !showVideoURLInput && !media.file && <div className="upload-options">
            <button onClick={() => setShowInput(true)} >Add Youtube link</button>
            <label htmlFor="image">Upload image</label>
        </div>
    }

    const website = musicianData.website && musicianData.website.length > 25 ? musicianData.website?.slice(0,25) + '...' : musicianData.website;

    return (
        <div className="musician-profile">
            <Modal collapsible onClose={() => {
                setShowInput(false);
                setMedia({ file: null, caption: '' })
            }}>
                <input type="file" accept="image/*" id="image" onChange={e => {
                    saveMedia(e.target.files![0])

                }} hidden />
                <h4>Upload an Image or a Video</h4>
                <p>{media.file && media.file.name}</p>
                {renderMediaInput()}
                {showCaptionInput()}
                {renderUploadOptions()}
                {(showVideoURLInput || media.file) && <button className="primary-button rounded" onClick={e => handleMediaUpload()}>Upload</button>}
            </Modal>
            <div className="profile-card--images">
                <div className="profile-card--images__cover" style={musicianData.coverPhotoURL ? ({
                    backgroundImage: `url(${musicianData.coverPhotoURL})`
                }) : {}}>
                    <input type="file" accept="image/*" hidden />
                </div>
                <div className="profile-card--images__profile" style={musicianData.photoURL ? { backgroundImage: `url(${musicianData.photoURL})` } : {}}>
                    <input type="file" accept="image/*" hidden />
                </div>
            </div>
            <div className="container">
                <div className="details">
                    <div>
                        <h2>{musicianData.stageName}</h2>
                        <p className="text-16">{musicianData.location?.street_city_state}</p>
                    </div>
                    <div className="bio-song">
                        <p>{musicianData.bio}</p>
                        <a href={musicianData.website} target="_blank" rel="noopener noreferrer">{website}</a>
                    </div>
                    <p className="musician-profile__experience">{musicianData.experience && `${musicianData.experience} years of performing experience`}</p>
                    <div className="tags">
                        {musicianData.tags?.map((tag, i) => <div key={i} className="genre-tag">{tag}</div>)}
                    </div>
                </div>
                <div className="account-options">
                    <Link to={urls.UPDATE_PROFILE}>Update Profile</Link>
                    <Link to="#" onClick={() => {
                        setShowModal(true);
                    }}>+Photo/Video</Link>
                    <Link to={urls.ACCOUNT}>Account</Link>
                </div>
                <div className="media">
                    {renderMedia()}
                </div>
            </div>
        </div>
    )
}

const VenueProfile = () => {
    /* WARNING: DO NOT CHANGE NAME VALUES OF HTML ELEMENTS HERE. ANY CHANGE MAY AFFECT EXISTING FUNCTIONALITY */
    const { Modal, setShowModal, setModalContent } = useModal();
    const { user } = useContext(appContext);
    const venueUser = user as VenueUser;
    const inputRef = useRef<HTMLInputElement>(null);
    const mediator = useMediator();

    const updateProfile = async (data: MusicianProfileData) => {
        try {
            let alertId = showInfoAlert('Updating Profile', 'Processing');
            mediator.updateUserProfile(data, user!.uid);
            store.removeNotification(alertId);
            showInfoAlert('Profile successfully updated', 'Success');
            setShowModal(false);
        } catch (e) {
            const { message } = e;
            showErrorAlert(message);
        }
    }

    const renderModalHTML = (type: string, name: string, defaultValue?: any) => {
        const handleEmailUpdate = async () => {
            if (inputRef.current?.checkValidity()) {
                if (name === 'street_city_state') {
                    let location_text = inputRef.current?.value;
                    let coords;
                    try {
                        coords = await getLocationCoords(inputRef.current?.value);
                    } catch (e) {
                        showErrorAlert('Failed to get location co-ordinates. Please make sure you have entered a comma-delimited address and a working internet connection.');
                        return;
                    }
                    updateProfile({
                        location: {
                            street_city_state: location_text,
                            coordinate: coords
                        }
                    });
                } else {
                    updateProfile({ [name]: inputRef.current?.value });
                }
            } else {
                showErrorAlert('Invalid Input');
            }
        }
        setModalContent(
            <>
                <div className="form-group">
                    <label>{name}</label>
                    <input ref={inputRef} {...{ type, name, defaultValue }} required />
                </div>
                <button onClick={handleEmailUpdate} className="link-button">Save</button>
            </>
        );
        setShowModal(true);
    };

    const website = venueUser.website && venueUser.website.length > 25 ? venueUser.website?.slice(0,25) + '...' : venueUser.website;


    return (
        <>
            {user &&
                <div className="container profile">
                    <Modal collapsible>
                    </Modal>
                    <div className="profile__info">
                        <div>
                            <span>Venue Name</span>
                            <p>{venueUser.name}</p>
                        </div>
                        <button onClick={() => renderModalHTML('text', 'name', venueUser.name)}>Change</button>
                    </div>
                    <div className="profile__info">
                        <div>
                            <span>Location (street,city,state)</span>
                            <p>{venueUser.location?.street_city_state}</p>
                        </div>
                        <button onClick={() => renderModalHTML('text', 'street_city_state', venueUser.location?.street_city_state)}>Change</button>
                    </div>
                    <div className="profile__info">
                        <div>
                            <span>Website</span>
                            <p>{venueUser.website ? website : 'No website available'}</p>
                        </div>
                        <button onClick={() => renderModalHTML('url', 'website', venueUser.website)}>Change</button>
                    </div>
                    <Account />
                </div>}
        </>
    )
}

const Profile = () => {
    let { user } = useContext(appContext);

    return (
        <div>
            <Navigation withShadow />
            {user?.type === 'musician' ? <MusicianProfile /> : <VenueProfile />}
        </div>
    )
}

export default Profile;