import React, { useContext, useRef } from 'react';
import appContext from '../appContext';
import Navigation from './components/navigation';
import API from '../firebase';
import { useHistory } from 'react-router-dom';
import urls from '../urls';
import useModal from '../hooks/modal';
import { store } from 'react-notifications-component';
import { showErrorAlert, showInfoAlert } from '../utilities';
import useMediator from '../hooks/mediator';
import './account.scss';

const Account = () => {
    const { user } = useContext(appContext);
    let history = useHistory();
    const { Modal, setShowModal } = useModal();
    const emailRef = useRef<HTMLInputElement>(null);
    const mediator = useMediator();

    const handleEmailReset = async (email: string) => {
        try {
            if (user) {
                let id = showInfoAlert('We are updating your email', 'Updating Email...');
                await mediator.updateEmail(email);
                store.removeNotification(id);
                showInfoAlert('Email update successful', 'Success');
            }
        } catch (e) {
            setShowModal(false);
            const { message } = e;
            showErrorAlert(message);
        }
    }

    const handlePasswordChange = async () => {
        let id;
        try {
            id = showInfoAlert('We are sending a password-reset link to your email address. Follow that link to complete your password reset', 'Sending Resent link', 3500)
            API.sendPasswordResetLink(user?.email!);
        } catch (e) {
            const message = e.message;
            id && store.removeNotification(id);
            showErrorAlert(message)
        }
    }

    const handleSignOut = async () => {
        mediator.signOut();
        history.push(urls.HOME);
    }

    const saveEmailChange = () => {
        if (emailRef.current?.checkValidity()) {
            handleEmailReset(emailRef.current.value);
        } else {
            showInfoAlert('Email must be valid', 'Invalid Input');
        }
    }

    return (
        <div className="account">
            {user?.type === 'musician' && <Navigation withShadow />}
            <Modal collapsible>
                <div className="form-group">
                    <label>
                        New Email:
                    </label>
                    <input type="email" required ref={emailRef} />
                </div>
                <button onClick={saveEmailChange} className="link-button">Save</button>
            </Modal>
            <div className={`${user?.type === 'musician' ? 'container' : ''} account`}>
                <div className="profile__info">
                    <div>
                        <span>Email</span>
                        <p>{user?.email}</p>
                    </div>
                    <button onClick={() => setShowModal(true)}>Change</button>
                </div>
                <div className="profile__info">
                    <div>
                        <span>Password</span>
                        <p>********</p>
                    </div>
                    <button onClick={handlePasswordChange}>Change</button>
                </div>
                <button onClick={handleSignOut} className="link-button">Log Out</button>
            </div>
        </div>
    )
}

export default Account;