import React, { useContext, useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import appContext from '../../appContext';
import emailTemplates from '../../email-templates';
import API from '../../firebase';
import useMediator from '../../hooks/mediator';
import { ApplicationRecord, Gig, MusicianUser, Slot } from '../../interfaces';
import urls from '../../urls';
import { showErrorAlert, showInfoAlert, toLocaleTime } from '../../utilities';
import Navigation from '../components/navigation';
import './gig-application.scss';

const GigApplication = (props: RouteComponentProps<{}>) => {
    let { slot_id, gig_id } = props.match.params as any;
    const { user } = useContext(appContext);
    const musicianUser = user as MusicianUser;
    const [slot, setSlot] = useState<Slot>();
    const [gig, setGig] = useState<Gig>();
    const messageBoxRef = useRef<HTMLFormElement>(null);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const mediator = useMediator();

    useEffect(() => {
        const setGigAndSlot = (gigs: Gig[]) => {
            let [gig_] = gigs;
            let slot = gig_.slots!.find(s => s.id === slot_id);
            setSlot(slot);
            setGig(gig_);
            setLoading(false);
        }

        const loadGigApplicationData = () => {
            const hasNotAppliedForThisSlot = !musicianUser.applications.find(a => slot_id === a.slot_id);
            if (hasNotAppliedForThisSlot) {
                API.collectGigs([gig_id]).then(setGigAndSlot);
            } else {
                setLoading(false);
            }
        }

        loadGigApplicationData();
    }, [setSlot, setGig, gig_id, slot_id, setLoading, musicianUser.applications]);

    const submitApplication = async () => {
        let body = messageBoxRef.current?.querySelector('.message');
        let subject = messageBoxRef.current?.querySelector('input');
        if (messageBoxRef.current?.checkValidity() && body?.innerHTML && gig && slot && subject) {
            try {
                showInfoAlert('Submitting application','Processing...')
                await handleMessageSend(subject.value!, body.innerHTML);
                const newApplication: ApplicationRecord = { slot_id: slot!.id, gig_id, venueDecision: 'undecided', musicianOfferDecision: 'undecided' };
                updateUserApplications(newApplication);
                updateGigInterestedApplicants(newApplication);
                history.push(urls.HOME);
            } catch (e) {
                const { message } = e;
                showErrorAlert(message);
            }
        } else {
            showErrorAlert('failed to send message. Please make sure you have entered a message.');
        }
    }

    const handleMessageSend = async (subject: string, body: string) => {
        const templateContent = {
            musicianName: musicianUser.stageName,
            body
        };
        const msg = {
            template_id: emailTemplates.gigApplication,
            subject,
            personalizations: [
                {
                    to: [{
                        email: gig!.email!
                    }],
                    dynamic_template_data: templateContent
                }
            ]
        }
        await API.sendEmail(msg);
        showInfoAlert('Message sent', 'Success');
    }

    const updateUserApplications = (newApplication: ApplicationRecord) => {
        let updatedApplications: ApplicationRecord[] = [newApplication,...musicianUser.applications];
        mediator.updateUserProfile({ applications: updatedApplications }, user!.uid);
    }

    const updateGigInterestedApplicants = (newApplication: ApplicationRecord) => {
        const updatedApplicants = [{ musicianId: musicianUser.uid, ...newApplication },...gig!.interestedApplicants!];
        const addedNewImages = { gig_cover: false, stage_image: false };
        API.updateGig(addedNewImages, { ...gig!, interestedApplicants: updatedApplicants,hasUnreviewedApps: true });
    }

    const loadingMessage = () => loading ? <Loader type="TailSpin" className="loader" color="#E51968"/> : <p>Error loading related gig: Either you have already applied for this slot or the gig poster deleted this gig.</p>;

    return (
        <div>
            <Navigation withShadow />
            <div className="container gig-application">
                {
                    !loading && gig ?
                        <>
                            <h2>to: {gig.venue}</h2>
                            <form ref={messageBoxRef} className="message-box">
                                <input type="text" required defaultValue={`${gig.gigName} Application`} placeholder="Enter a subject" />
                                <div className="message" contentEditable>
                                    <p>Hi {gig.venue},</p>
                                    <br/>
                                    <p> I am {user?.displayName} interested in performing at {gig.gigName} on {(new Date(slot!.date)).toDateString()} from {toLocaleTime(gig.time_start!)} to {toLocaleTime(gig.time_end!)}.</p>
                                    <p>I have {musicianUser.experience} years of experience performing at other venues. Here is a link to my MatchBand profile which includes music links, my style, and more about myself.</p>
                                    <a href={musicianUser.url}>{musicianUser.url}</a>
                                    <p>If you want to message me, you can contact me at {musicianUser.email}.</p>
                                    <p>I look forward to hearing from you.</p>
                                    <br/>
                                    <p>Thanks,</p>
                                    <p>{musicianUser.stageName}</p>
                                </div>
                            </form>
                            <button className="primary-button rounded" onClick={submitApplication}>Send</button>
                        </> : loadingMessage()
                }
            </div>
        </div>
    )
}

export default GigApplication;