import React, { useContext } from 'react';
import Navigation from './components/navigation';
import useTags from '../hooks/genre-tags';
import { MusicianUser } from '../interfaces';
import appContext from '../appContext';
import { useHistory } from 'react-router-dom';
import urls from '../urls';
import { showErrorAlert } from '../utilities';
import './update-tags.scss';
import useMediator from '../hooks/mediator';


const UpdateTags = ({ setShowModal }: { setShowModal ?: React.Dispatch<React.SetStateAction<boolean>>}) => {
    let { user } = useContext(appContext);
    let musicianData = user as MusicianUser;
    const { TagsComponent,selectedTags } = useTags(musicianData.tags);
    let history = useHistory();
    const mediator = useMediator();

    const saveChanges = async () => {
        try{
            mediator.updateUserProfile({ tags: selectedTags },user!.uid)
            !setShowModal ? history.push(urls.UPDATE_PROFILE) : setShowModal(false);
        } catch(e) {
            const message = e.message;
            showErrorAlert(message);
        }
    }

    return (
        <>
            {!setShowModal && <Navigation withShadow />}
            <div className="container update-tags">
                <h2>Select or search tags that define your style.</h2>
                <TagsComponent placeholder="Search for tags" />
                <button className="primary-button rounded" onClick={saveChanges}>Save</button>
            </div>
        </>
    )
}

export default UpdateTags;