import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import appContext from '../../appContext';
import urls from '../../urls';

const Navigation = ({ noProfile, withShadow }: { noProfile?: boolean, withShadow?: boolean }) => {
    const { user } = useContext(appContext);
    const photoURL = user?.photoURL ? user?.photoURL : require('../../assets/user-profile.svg');
    return (
        <nav className={`top-nav ${withShadow ? 'withShadow' : ''}`}>
            <div>
                <Link className="logo" to={urls.HOME}>MatchBand</Link>
                <Link to={!noProfile ? urls.PROFILE : ''} className="profile-photo" style={{
                    'backgroundImage': `url('${photoURL}')`
                }}></Link>
            </div>
        </nav>
    )
}

export default Navigation;