import React, { useContext, useState, useEffect, useRef } from 'react';
import Navigation from '../components/navigation';
import appContext from '../../appContext';
import { MusicianUser, MusicianProfileData } from '../../interfaces';
import './update-profile.scss';
import { previewPhoto, getLocationCoords, showInfoAlert, showErrorAlert } from '../../utilities';
import API from '../../firebase';
import { useHistory } from 'react-router-dom';
import urls from '../../urls';
import useUrlVerify from '../../hooks/verifyProfileUrl';
import { store } from 'react-notifications-component';
import useMediator from '../../hooks/mediator';
import useModal from '../../hooks/modal';
import UpdateTags from '../update-tags';

const UpdateMusicianProfile = () => {
    let { user } = useContext(appContext);
    let musicianData = user as MusicianUser;
    const [data, setData] = useState<MusicianProfileData>();
    const formRef = useRef<HTMLFormElement>(null);
    const coverRef = useRef<HTMLInputElement>(null);
    const photoRef = useRef<HTMLInputElement>(null);
    const { getUrl, UrlComponent } = useUrlVerify(musicianData.url);
    const history = useHistory();
    const mediator = useMediator();
    const { Modal, setShowModal } = useModal();

    useEffect(() => {
        setData({
            coverPhotoURL: musicianData.coverPhotoURL,
            website: musicianData.website,
            photoURL: musicianData.photoURL,
            bio: musicianData.bio,
            location: musicianData.location,
            experience: musicianData.experience,
            tags: musicianData.tags,
            stageName: musicianData.stageName
        });
    }, [musicianData])

    const handleSave = async () => {
        if (formRef.current?.checkValidity()) {
            let _data: any = {};
            let inputs = formRef.current.querySelectorAll('input');
            for (var i = 0; i < inputs.length; i++) {
                _data[inputs[i].name] = inputs[i].value;
                if(inputs[i].name === 'location'){
                    _data['location'] = {
                        street_city_state: inputs[i].value,
                        coordinate: await getLocationCoords(inputs[i].value)
                    }
                }
            }
            try{
                let coverPhotoURL = coverRef.current?.files?.length ? await API.saveImage(coverRef.current.files[0],'cover') as string : data!.coverPhotoURL;
                let photoURL = photoRef.current?.files?.length  ? await API.saveImage(photoRef.current.files[0],'profile') as string : data!.photoURL;
                let new_data = { ..._data, url: getUrl(), coverPhotoURL,photoURL };
                let alertId = showInfoAlert('Updating Profile','Processing...');
                mediator.updateUserProfile(new_data,user!.uid);
                store.removeNotification(alertId);
                showInfoAlert('Profile successfully updated','Success',1000);
                history.push(urls.PROFILE);
            } catch(e){
                const { message } = e;
                showErrorAlert(message)
            }
        } else {
            formRef.current?.reportValidity();
            // showErrorAlert()
        }
    }

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let files = e.target.files;
        if(files){
            previewPhoto(files[0], e.target.parentElement);
        }
    }

    return (
        <div className="update-profile">
            <Navigation withShadow/>
            <Modal collapsible>
                <UpdateTags {...{ setShowModal }}/>
            </Modal>
            <div className="profile-card--images">
                <label className="profile-card--images__cover" style={data?.coverPhotoURL ? { backgroundImage: `url(${data.coverPhotoURL})` } : {}}>
                    <input type="file" accept="image/*" id="cover" ref={coverRef} hidden onChange={handleImageChange} />
                </label>
                <label className="profile-card--images__profile" style={data?.photoURL ? { backgroundImage: `url(${data?.photoURL})` } : {}}>
                    <input type="file" accept="image/*" id="profile" ref={photoRef} hidden onChange={handleImageChange} />
                </label>
            </div>
            <div className="container">
                <div className="profile-images__controls text-12">
                    <span>
                        Change 
                    </span>
                    <label htmlFor="profile">
                            Profile/
                    </label>
                    <label htmlFor="cover">
                            Cover Photo
                    </label>
                </div>
                <form onSubmit={(e) => e.preventDefault()} ref={formRef}>
                    <div className="form-group">
                        <label htmlFor="stage">Stage Name</label>
                        <input type="text" placeholder="Stage Name" defaultValue={data?.stageName} required name="stageName" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="location">Location (where are you looking for gigs?)</label>
                        <input type="text" placeholder="City,State" defaultValue={data?.location?.street_city_state} required name="location" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="website">Website Link (Optional)</label>
                        <input type="text" name="website" defaultValue={data?.website} placeholder="Youtube,Soundcloud,Spotify" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="bio">Quick Bio</label>
                        <input type="text" name="bio" defaultValue={data?.bio} placeholder="What do you want venues to know about you?" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="experience">Years of Experience (Optional)</label>
                        <input type="number" name="experience" defaultValue={data?.experience} placeholder="Years of Experience" />
                    </div>
                    <UrlComponent />
                </form>
                <div className="tags">
                    {data?.tags?.map((tag, i) => (<div key={i} className="genre-tag">{tag}</div>))}
                    <button className="update-tag" onClick={() => setShowModal(true)}>Update</button>
                </div>
                <button className="primary-button rounded" onClick={handleSave}>Save</button>
            </div>
        </div>
    )
}


export default UpdateMusicianProfile;