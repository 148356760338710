import imageCompression from 'browser-image-compression';
import { Slot } from './interfaces';
import firebase from 'firebase';
import API from './firebase';
import uuid from 'react-uuid';
import { store } from 'react-notifications-component';

export const compressImage = async (image: File, options?: {
    maxWidthOrHeight?: number,
    fileType?: string
}): Promise<File> => {
    return new Promise(async (ok, no) => {
        const defaultOptions = {
            ...options,
            maxSizeMB: 1,
            useWebWorker: true
        };
        try {
            const compressed = await imageCompression(image, defaultOptions) as File;
            ok(compressed);
        } catch {
            no(new Error('failed to compress image'))
        }
    })
}

export const addGigCoverStyling = (url: string | undefined) => {
    return {
        'backgroundImage': `url(${url ? url : require('./assets/image-add-bg.jpg')})`,
        'height': '200px',
        'backgroundSize': 'cover',
        'backgroundPosition': 'center',
        'backgroundRepeat': 'no-repeat'
    };
}

export const previewPhoto = (file: File | null | undefined, container: HTMLElement | null) => {
    let reader = new FileReader();
    if (file && container) {
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
            let url = e.target?.result;
            container.style.backgroundImage = `url(${url})`;
            container.style.backgroundSize = 'cover';
            container.style.backgroundRepeat = 'no-repeat';
            container.style.backgroundPositionX = 'center';
        }
    }
}

export const getLocationCoords = (location: string) => {
    const { key } = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? require('./apiKey.json') : { key: process.env.REACT_APP_MAP_KEY };
    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${location.replace(' ', '+')}&key=${key}`;
    return fetch(url).then(async res => {
        const { results } = await res.json();
        let { location } = results[0].geometry;
        return new firebase.firestore.GeoPoint(location.lat, location.lng)
    })
}

export const verifyUrlUniqueness = async (url: string, type: 'url' | 'userId') => {
    let username = type === 'url' ? url.match(/\/\w+/)![0] : url;
    let users = await API.fetchUserProfiles();
    return username && username.length === 1 && users.some(item => item.user_id === username);
}

export const getProfileUrl = (stageName: string) => {
    let user_id = stageName.replace(/ /g, '').toLocaleLowerCase();
    let i = 1;
    while (!verifyUrlUniqueness(user_id, 'userId')) {
        user_id += i.toString()
        i += 1;
    }
    return `${window.location.host}/in/${user_id}`;
}

export const getPathFromDownloadURL = (downloadUrl: string, folder: string) => {
    let result = downloadUrl.match(`(${folder}.*)\\?`);
    let new_path = result ? result[1].replace(/%2F/g,'/').replace(/%20%/g,' ') : '';
    return new_path;
}

export const getYouTubeEmbedUrl = (url: string) => {
    let matches = url.match(/v=(.*)/);
    let id = matches![1];
    return 'https://youtube.com/embed/' + id;
}

export const getMonthText = (index: number) => {
    let months: any = {
        0: 'Jan',
        1: 'Feb',
        2: 'Mar',
        3: 'Apr',
        4: 'May',
        5: 'Jun',
        6: 'Jul',
        7: 'Aug',
        8: 'Sep',
        9: 'Oct',
        10: 'Nov',
        11: 'Dec'
    }
    return months[index];
}

const common = {
    none: 'None',
    experience: 'Years of experience',
    location: 'Location'
}
let musicianOptions = {
    none: 'None',
    relevancy: 'Relevancy(Based on your style tags)',
    location: 'Location',
    new: 'Newly Posted',
    coming_soon: 'Upcoming Soon',
    compensation: 'Compensation',
    experience: 'Years of experience',
    length: 'Gig length (highest to lowest)',
}

export const SearchSortOptions = (user_type: 'musician' | 'venue'): Partial<typeof musicianOptions> => {
    if (user_type === 'musician') {
        return musicianOptions;
    }
    return common;
}


export const SearchFilterOptions = () => {
    return {
        none: 'None',
        daytime: 'Daytime (9am - 4pm)',
        night: 'Night (4pm - 12am)',
        late: 'Late Night (12am - 9am)',
        weekdays: 'Weekdays',
        weekends: 'Weekends',
        my_style: 'My Style Tags'
    }
}

export const toLocaleTime = (time: string) => {
    let res = time.match(/\d+/g);
    if(!res){
        throw new Error('Invalid time format');
    }
    let [hours,minutes] = res;
    return `${+hours % 12}:${minutes}${+hours % 12 !== +hours ? ' pm' : ' am'}`
}

export const generateTimeSlots = (frequency: 'monthly' | 'once' | 'weekly' | 'multiple', dates: string[]) => {
    let slots: Slot[];
    if (frequency === 'monthly' || frequency === 'weekly') {
        let [start, end] = dates.map(d => new Date(d));
        slots = [{
            date: dates[0],
            id: uuid(),
            offer_accepted: false,
            assignedTo: ''
        }];
        let current_date = start;
        while (current_date <= end) {
            let time;
            if (frequency === 'weekly') {
                time = current_date.setDate(current_date.getDate() + 7);
            } else {
                time = current_date.setMonth(current_date.getMonth() + 1);
            }
            current_date = new Date(time);
            if (current_date <= end) {
                slots.push({
                    date: current_date.toDateString(),
                    id: uuid(),
                    offer_accepted: false,
                    assignedTo: ''
                });
            }
        }
    } else {
        slots = dates.map(d => ({ date: new Date(d).toDateString(), offer_accepted: false, id: uuid(), assignedTo: '' })).sort()
    }
    return slots;
}

export const showErrorAlert = (message: string,duration?:number) => {
    return store.addNotification({
        title: 'Error',
        type: 'warning',
        message,
        container: 'top-center',
        dismiss: {
            duration: duration !== undefined ? duration : 1500,
            showIcon: true
        }
    })
};

export const showInfoAlert = (message: string, title: string, duration?: number, showIcon?: boolean, onScreen?:boolean) => {
    return store.addNotification({
        title,
        type: 'info',
        message,
        container: 'top-center',
        dismiss: {
            duration: duration !== undefined ? duration : 1500,
            onScreen,
            showIcon
        }
    })
}