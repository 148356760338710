import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import appContext from '../../appContext';
import useModal from '../../hooks/modal';
import { GigData, VenueUser, Slot } from '../../interfaces';
import urls from '../../urls';
import Navigation from '../components/navigation';
import 'react-calendar/dist/Calendar.css';
import useTags from '../../hooks/genre-tags';
import './new-gig.scss';
import { generateTimeSlots, getLocationCoords, previewPhoto, showInfoAlert } from '../../utilities';
import DatePicker from '../components/datePicker';
import useMediator from '../../hooks/mediator';


const useSteps = () => {
    let stepCount = 9;
    const [currentStep, setCurrent] = useState(0);
    const { user } = useContext(appContext);
    const VenueData = user as VenueUser;
    const [gigData, setGigData] = useState<Partial<GigData>>();
    const history = useHistory();
    const mediator = useMediator();

    useEffect(() => {
        if (user) {
            setGigData({
                posted_by: user.uid,
                venue: VenueData.name,
                gigName: '',
                address: '',
                email: VenueData.email,
                description: '',
                frequency: undefined,
                dates: [],
                time_start: '',
                time_end: '',
                tags: [],
                experience: '',
                compensation: '',
                negotiable: false,
                equipment: '',
                performance_space_size: '',
                stage_image: null,
                setlist: [],
                gig_cover: null,
                date_posted: '',
                interestedApplicants: [],
                slots: [],
                location: VenueData.location,
                hasUnreviewedApps: false
            })
        }
    }, [user, VenueData])

    const useValidator = (validator: () => false | Partial<GigData>) => {
        const handleGigPublish = async (gigData_: GigData) => {
            if (user && gigData_) {
                try {
                    let confirmed = window.confirm('Gig creation complete. Are you sure you want to publish this?');
                    if (!confirmed) {
                        return;
                    }
                    let slots: Slot[] = generateTimeSlots(gigData?.frequency!, gigData_.dates);
                    let location = gigData_.location;
                    if (gigData_.address !== VenueData.location.street_city_state) {
                        let coords = await getLocationCoords(gigData_.address);
                        location = {
                            street_city_state: gigData_.address,
                            coordinate: coords
                        }
                    }
                    let gig = { ...gigData_, date_posted: new Date().toISOString(), slots, location };
                    showInfoAlert('We are posting your gig', 'Publishing...');
                    await mediator.publishGig(gig);
                    history.push(urls.HOME)
                } catch (e) {
                    alert(e.message)
                }
            }
        }

        const handleNextNavAction = async () => {
            let data = validator();
            if (data) {
                if (currentStep === stepCount) {
                    await handleGigPublish({ ...gigData!, ...data } as GigData);
                } else {
                    setGigData(prev => ({ ...prev!, ...data }))
                    setCurrent(prev => prev < stepCount ? prev + 1 : prev);
                }
            } else {
                window.alert('All required fields must be filled');
            }
        }
        const notFirstStep = currentStep > 0;
        const Navigation = () => (
            <div className={`navigation-buttons ${!notFirstStep ? 'single-button' : ''}`}>
                {notFirstStep && <button type="button" className="link-button back" disabled={currentStep === 0} onClick={() => setCurrent(prev => prev > 0 ? prev - 1 : prev)}>Back</button>}
                <button type="button" className="link-button next" onClick={handleNextNavAction}>{currentStep === stepCount ? 'Publish' : 'Next'}</button>
            </div>
        )
        return {
            Nav: Navigation,
            tryNavigateForward: handleNextNavAction
        }
    }

    // gig detail
    const Step1 = () => {
        const formRef = useRef<HTMLFormElement>(null);
        const emailRef = useRef<HTMLInputElement>(null);
        const [email, setEmail] = useState(user!.email);

        let { Nav, tryNavigateForward } = useValidator(() => {
            let data: any = {};
            if (formRef.current?.checkValidity()) {
                let inputs = formRef.current.querySelectorAll('input');
                for (let i = 0; i < inputs.length; i++) {
                    const element = inputs[i];
                    data[element.id] = element.value;
                }
                data.email = email;
                return data as GigData;
            }
            return false
        });
        let { Modal, setShowModal } = useModal();

        return (
            <>
                <Modal collapsible>
                    <label>
                        <span>Email</span>
                        <input type="email" required ref={emailRef} />
                    </label>
                    <button onClick={() => {
                        if (emailRef.current?.checkValidity()) {
                            setEmail(emailRef.current?.value as string);
                            setShowModal(false);
                        } else {
                            window.alert('Invalid email format')
                        }
                    }}>Submit</button>
                </Modal>
                <form ref={formRef} onSubmit={tryNavigateForward}>
                    <div className="title">
                        <h1>Create a new gig</h1>
                    </div>
                    <div className="form-group">
                        <label htmlFor="gigName">
                            Gig Name
                    </label>
                        <input type="text" id="gigName" placeholder="Gig Name" defaultValue={gigData?.gigName} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="address">
                            Address
                    </label>
                        <input type="address" required id="address" defaultValue={gigData?.address ? gigData.address : VenueData.location.street_city_state} placeholder="Street, City, State" />
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <div className="email-change">
                            <p>{email}</p>
                            <button onClick={() => setShowModal(true)} className="link-button" type="button">Change</button>
                        </div>
                    </div>
                    <p>
                        NOT publicly visible. When a musician applies to the gig, MatchBand will deliver their message to this email.
                </p>
                    <div className="form-group">
                        <label htmlFor="description">
                            Short Description
                    </label>
                        <input type="text" id="description" defaultValue={gigData?.description} required />
                    </div>
                    <Nav />
                </form>
            </>
        )
    }

    // gig frequency
    const Step2 = () => {
        const [selected, setSelected] = useState(gigData?.frequency);
        type FrequencyType = "monthly" | "once" | "weekly" | "multiple"

        const handleClick = (value: FrequencyType) => {
            setSelected(value);
        }

        const { Nav } = useValidator(() => selected ? { frequency: selected } : false);

        const renderOptions = () => {
            let options: { Desc: string, eg: string, type: FrequencyType }[] = [
                {
                    Desc: 'One time',
                    eg: 'i.e Jul 5',
                    type: 'once'
                }, {
                    Desc: 'Multiple times',
                    eg: '(i.e Jul 4 & Jul 5)',
                    type: 'multiple'
                }, {
                    Desc: 'Weekly',
                    eg: '(i.e every Sat from 9 Jan to 27 Jan)',
                    type: 'weekly'
                }, {
                    Desc: 'Monthly',
                    eg: '(i.e second Sat. of every month)',
                    type: 'monthly'
                }
            ]
            return options.map(({ Desc, eg, type }, i) => (
                <li key={i} className={selected === type ? 'selected' : ''}>
                    <button onClick={() => handleClick(type)} >
                        <p>{Desc}</p>
                        <p>{eg}</p>
                    </button>
                </li>
            ))
        }

        return (
            <div className="buttoned-list">
                <div className="title">
                    <h2>How often is your gig?</h2>
                </div>
                <ul>
                    {renderOptions()}
                </ul>
                <Nav />
            </div>
        )
    }

    // dates
    const Step3 = () => {
        const [dates, setDates] = useState<string[]>(gigData ? gigData.dates! : []);

        const { Nav } = useValidator(() => {
            if (!dates.length || (gigData?.frequency !== 'once' && dates.length <= 1)) {
                return false;
            }
            return { dates }
        });

        return (
            <div className="date-picker">
                <div className="title">
                    <h2>{gigData?.frequency === 'monthly' || gigData?.frequency === 'weekly' ? 'Select your start date and end date' : 'Select your date(s)'}</h2>
                </div>
                <DatePicker {...{ frequency: gigData!.frequency!, dates, setDates }} />
                <Nav />
            </div>
        )
    }

    // times
    const Step4 = () => {
        const formRef = useRef<HTMLFormElement>(null);
        const { Nav } = useValidator(() => {
            let form = formRef.current;
            if (form?.checkValidity()) {
                let times = form.querySelectorAll('input');
                let time_start = times[0].value,
                    time_end = times[1].value
                return {
                    time_start,
                    time_end
                }
            }
            return false;
        });

        return (
            <div>
                <div className="title">
                    <h2>Select your time</h2>
                    <p>This will apply to every date you selected.</p>
                </div>
                <form ref={formRef}>
                    <div className="form-group">
                        <label htmlFor="timeStart">Time Start</label>
                        <input type="time" id="timeStart" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="timeEnd">Time End</label>
                        <input type="time" id="timeEnd" required />
                    </div>
                </form>
                <Nav />
            </div>
        )
    }

    // tags
    const Step5 = () => {
        const { selectedTags, TagsComponent } = useTags();
        const { Nav } = useValidator(() => ({ tags: selectedTags }))
        return (
            <div>
                <div className="title">
                    <h2>Select or search tags that describe the styles you are open to.</h2>
                </div>
                <p>If your tags are guidelines rather than strict requirements or if you are open to any style, use the tag “Flexible.”</p>
                <TagsComponent />
                <Nav />
            </div>
        )
    }

    // experience
    const Step6 = () => {
        const formRef = useRef<HTMLInputElement>(null);
        const { Nav } = useValidator(() => {
            let input = formRef.current;
            if (input?.checkValidity()) {
                return {
                    experience: input.value
                }
            }
            return false;
        })
        return (
            <div>
                <div className="title">
                    <h2>Minimum Experience Preferred</h2>
                </div>
                <div className="form-group">
                    <label htmlFor="experience">Minimum Years of Experience</label>
                    <input type="number" defaultValue={gigData?.experience} id="experience" required min="1" ref={formRef} />
                </div>
                <Nav />
            </div>
        )
    }

    // compensation page
    const Step7 = () => {
        const formRef = useRef<HTMLFormElement>(null);
        const { Nav } = useValidator(() => {
            let form = formRef.current;
            if (form?.checkValidity()) {
                let inputs = form.querySelectorAll('input');
                return {
                    compensation: inputs[0].value,
                    negotiable: inputs[1].checked
                }
            }
            return false;
        })

        return (
            <div>
                <div className="title">
                    <h2>Compensation</h2>
                </div>
                <p>Unfortunately, MatchBand cannot process monetary compensation for the time being. For now, please communicate with your selected performer on how they will receive monetary compensation. (i.e. cash, check, Venmo)</p>
                <form ref={formRef} onSubmit={e => e.preventDefault()}>
                    <div className="form-group">
                        <label htmlFor="compensation">Compensation Description (For a 2.5 hour gig)</label>
                        <input type="text" id="compensation" defaultValue={gigData?.compensation} required />
                    </div>
                    <label>
                        <input type="checkbox" id="negotiable" defaultChecked={gigData?.negotiable} />
                        <span>Negotiable based on experience</span>
                    </label>
                </form>
                <Nav />
            </div>
        )
    }

    // Equipment & Performance Space Size
    const Step8 = () => {
        const formRef = useRef<HTMLFormElement>(null);
        const { Nav } = useValidator(() => {
            let data: any = {};
            let form = formRef.current;
            if (form?.checkValidity()) {
                let inputs = form.querySelectorAll('input');
                for (const input of inputs) {
                    data[input.id] = input.value;
                    if (input.type === 'file') {
                        data[input.id] = input.files && input.files[0];
                    }
                }
                return data as GigData;
            }
            return false
        });

        const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let files = e.target.files;
            if (files) {
                previewPhoto(files[0], e.target.parentElement);
            }
        }

        return (
            <div className="perf-space">
                <div className="title">
                    <h2>Equipment &amp; Performance Space Size</h2>
                </div>
                <form ref={formRef} onSubmit={e => e.preventDefault()}>
                    <div className="form-group">
                        <label htmlFor="equipment">Equipment Provided</label>
                        <input type="text" id="equipment" defaultValue={gigData?.equipment} required placeholder="microphones, stool, music stand, instruments" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="performance_space_size">Performance Space Size - What is the maxium amount of people your performance space can comfortably accomodate?</label>
                        <input type="number" min="1" required defaultValue={gigData?.performance_space_size} id="performance_space_size" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="stage_image">
                            <span>Upload a picture of your performance space so the musician can get a sense of its size. *Optional</span>
                            <div className="image_input">
                                <input type="file" id="stage_image" accept="image/*" onChange={handleImageChange} hidden />
                            </div>
                        </label>
                    </div>
                </form>
                <Nav />
            </div>
        )
    }

    // setlist
    const Step9 = () => {
        const [hasRecommendation, setHasRec] = useState<true | false | null>(null);
        const [setList, updateList] = useState<string[]>([]);
        const { Nav } = useValidator(() => {
            if (setList.length) {
                return { setlist: setList }
            }
            return false;
        });
        const [song, setSong] = useState<string>('');

        const addRecommendation = () => {
            if (song) {
                updateList([...setList, song])
            } else {
                alert('input must be filled');
            }
            setSong('')
        }

        const handleSongChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setSong(e.target.value);
        }

        return (
            <>
                {
                    hasRecommendation == null &&
                    <div className="buttoned-list">
                        <div className="title">
                            <h2>Do you have a recommended setlist?</h2>
                        </div>
                        <ul>
                            <li><button onClick={() => setCurrent(prev => prev + 1)}>No, performer can choose.</button></li>
                            <li><button onClick={() => setHasRec(true)}>I have some suggestions.</button></li>
                        </ul>
                        <Nav />
                    </div>
                }
                {
                    hasRecommendation &&
                    <div>
                        <div className="title">
                            <h2>Make some song or artist recommendations</h2>
                        </div>
                        <form onSubmit={(e) => { e.preventDefault(); addRecommendation() }}>
                            <div className="form-group">
                                <label htmlFor="recommendation">Enter your Recommendation</label>
                                <input type="text" value={song} onChange={handleSongChange} id="recommendation" />
                            </div>
                            <button type="button" className="link-button" onClick={addRecommendation}>Add Recommendation</button>
                        </form>
                        <div className="setlist">
                            <ul>
                                {setList.map((rec, i) => <li key={i} className="removable songs" onClick={() => updateList(prev => prev.filter((_, index) => index !== i))}>{i + 1}. {rec}</li>)}
                            </ul>
                        </div>
                        <Nav />
                    </div>
                }
            </>
        )
    }

    // Ad cover
    const Step10 = () => {
        const imgContainerRef = useRef<HTMLDivElement>(null);
        const { Nav } = useValidator(() => ({}));

        useEffect(() => {
            if (gigData?.gig_cover) {
                previewPhoto(gigData?.gig_cover, imgContainerRef.current)
            }
        }, [])

        return (
            <div>
                <div className="title">
                    <h2>Add a Photo</h2>
                    <p>The ad will have your next upcoming gig date.</p>
                </div>
                <div className="ad-card">
                    <p className="ad-card__date text-12">
                        {`Starting ${gigData!.dates![0].slice(0, 10)}`}
                    </p>
                    <label>
                        <input type="file" hidden id="ad_img" onChange={(e) => {
                            const target = e.target;
                            setGigData(prev => ({ ...prev!, gig_cover: target.files![0] }))
                        }} />
                        <div ref={imgContainerRef} className="ad-card__img image_input">
                        </div>
                    </label>
                    <div className="ad-card__description">
                        <div className="status-box">
                            <div className="st-message">
                                <p>{VenueData.name} posted on {(new Date()).toDateString()}</p>
                                <p>
                                    <img src={require('./../../assets/location-icon.svg')} alt="" />
                                    {`${gigData!.address?.split(',').slice(1,).join(',')}.`}
                                </p>
                            </div>
                        </div>
                        <h3>{gigData?.gigName}</h3>
                        <p>{gigData?.description}</p>
                        <ul className="ad-card__tags">
                            {gigData?.tags?.map((tag, i) => <li className="genre-tag" key={i}>{tag}</li>)}
                        </ul>
                    </div>
                </div>
                <Nav />
            </div>
        )
    }

    const steps = [Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8, Step9, Step10];

    return steps[currentStep];
}


const NewGig = () => {
    const { user } = useContext(appContext);
    const history = useHistory();
    const Step = useSteps();
    useEffect(() => {
        if (user && user.type !== 'venue') {
            history.push(urls.HOME);
        }
    }, [user, history])
    return (
        <>
            <Navigation withShadow />
            <div className="container new-gig">
                <Step />
            </div>
        </>
    )
}

export default NewGig;