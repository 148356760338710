import React from 'react';
import { useHistory } from 'react-router-dom';
import { Slot } from '../../interfaces';
import urls from '../../urls';
import { toLocaleTime } from '../../utilities';
import './slot.scss';

const SlotCard = ({
    user_type,
    slot,
    time_start,
    time_end,
    gig_id,
    disabled
 }: {
    user_type: 'musician' | 'venue';
    slot: Slot;
    time_start: string;
    time_end: string;
    gig_id: string;
    disabled ?: boolean
}) => {
    const history = useHistory();
    return (
        <div className="slot-card">
            <div className="slot-card__date text-14">{(new Date(slot.date)).toDateString()}</div>
            <div className="slot-card__time">{toLocaleTime(time_start)} - {toLocaleTime(time_end)}</div>
            {user_type === 'musician' ? <button className="primary-button rounded" {...{disabled}} onClick={() => !disabled && history.push(`${urls.GIG_APPLICATION}/${gig_id}/${slot.id}`)}>Apply</button> : null}
        </div>
    )
}

export default SlotCard;