import React, { useEffect } from 'react';
import Calendar from 'react-calendar';
import { generateTimeSlots, showInfoAlert } from '../../utilities';
import './date-picker.scss';

const DatePicker = ({
    dates,
    frequency,
    setDates
}: {
    dates: string[],
    frequency: "multiple" | "monthly" | "once" | "weekly",
    setDates: React.Dispatch<React.SetStateAction<string[]>>
}) => {

    useEffect(() => {
        if (frequency !== 'multiple') {
            setDates([])
        }
    }, [frequency, setDates])

    const sortDates = (a: string,b:string) => new Date(a).getTime() - new Date(b).getTime();

    const handleChange = (date: Date | Date[]) => {
        let date_ = (date as Date).toDateString();
        if (dates.includes(date_)) {
            return;
        }
        if (frequency === 'multiple') {
            setDates(dates => ([...dates, date_].sort(sortDates)))
        } else if (frequency !== 'once') {
            setDates(dates => (dates.length < 2 ? [...dates, date_].sort(sortDates) : [date_]))
        } else {
            setDates([date_])
        }
    }

    const handleDelete = (index: number) => {
        if (dates.length > 1) {
            setDates(dates => dates.filter((_, i) => i !== index))
        } else {
            showInfoAlert('At least one date must be selected', 'Invalid input');
        }
    }

    const statusMessage = () => {
        if(dates.length < 2){
            return ''
        }
        if(frequency === 'weekly'){
            return `repeats weekly on every ${dates[0].slice(0,3)} from ${dates[0]} to ${dates[1]}`
        }
        if(frequency === 'monthly'){
            let allDates = generateTimeSlots(frequency,dates).map(s => s.date).join(', ')
            return `repeats monthly on: ${allDates}`
        }
    }

    const isDeletable = frequency === 'multiple';

    return (
        <div className="date-picker-component">
            <Calendar onClickDay={handleChange} selectRange={(frequency !== 'once') && (frequency !== 'multiple')} minDate={new Date()} />
            <div className="selected-dates">
                <p className="label">Dates selected</p>
                <ul>
                    {dates.map((date, i) => <li key={i} className={`dates ${isDeletable ? 'removable' : ''}`} onClick={isDeletable ? () => handleDelete(i) : () => { }}>{date}</li>)}
                </ul>
            </div>
            <p className="st-text">{statusMessage()}</p>
        </div>
    )
}

export default DatePicker;