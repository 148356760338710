export default {
    offerRejection: 'd-a73acc414fab4ad09d259cb6ec1559e7',
    offerAcceptance: 'd-1e7c07bdb65242ab9d2fd79a0087ece8',
    offerRescind: 'd-3c4a9f2b619c4be7aeefe5dd6e6b614a',
    applicationApproval: 'd-d46af7499df1443094aa2ae922a3cebe',
    applicationRejection: 'd-1d23a517660646f7ad05e5ac55108e71',
    gigApplication: 'd-f8f09d134ed049ac8c1fa5e43f767931',
    musicianInvite: 'd-1bbefe5d93324424bf91193755044661',
    verifyEmail: 'd-b54116fdf95d4624a8c68f899113e883',
    passwordReset: 'd-e20d2940059c438990a20669d4c54679'
}