import React, { useEffect, useState } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import Home from '../pages/home';
import appContext from '../appContext';


const PrivateRoute = (props: RouteProps) => {
    const [isSignedIn,setIsSignedIn] = useState(false);
    const ctx = React.useContext(appContext);

    useEffect(() => {
        setIsSignedIn(ctx.user !== null);
    },[ctx.user])
    return (
        isSignedIn ? <Route {...props} /> : <Route path={props.path} render={() => <Home authMode={true} />}/>
    )
}

export default PrivateRoute;