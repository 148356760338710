import React, { PropsWithChildren, useState } from 'react';


const useDropDown = () => {
    const [show, showDropDown] = useState(false);

    const DropDown = ({ children }: PropsWithChildren<{}>) => {
        return (
            show ?
                <div className="dropdown">
                    {children}
                </div>
                : null
        )
    }

    return {
        DropDown,
        showDropDown
    }
}

export default useDropDown;