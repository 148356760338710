import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MusicianProfileSetup from './musician/profile-setup';
import Navigation from './components/navigation';
import VenueProfileSetup from './venue/profile-setup';
import API from '../firebase';
import { AuthUser } from '../interfaces';
import { showErrorAlert, showInfoAlert } from '../utilities';
import useModal from '../hooks/modal';
import SignIn from './sign-in';

const CreateProfile = () => {
    const location = useLocation();
    let params = new URLSearchParams(location.search);
    let code = params.get('code');
    let userType = params.get('userType');
    let email = params.get('email');
    const [validated, setValidated] = useState(false);
    const [isValidating, setIsValidating] = useState(true);
    const [authUser, setAuthUser] = useState<AuthUser>();
    const { Modal, setShowModal } = useModal();

    useEffect(() => {
        const componentDidMount = async () => {
            if (validated || !isValidating) {
                return;
            }
            let user_ = await API.getSpecifiedUser(email!);
            setAuthUser(user_);
            try {
                await API.auth.applyActionCode(code!);
                setValidated(true);
                showInfoAlert('Your account has been successfully verfified', 'Account Verified')
            } catch (e) {
                setIsValidating(false);
                let hasProfile = (await API.fetchUser(user_.uid)) !== undefined;
                if (user_?.emailVerified && !hasProfile) {
                    setValidated(true);
                } else if (hasProfile) {
                    showErrorAlert('This account already has a profile',5000)
                } else {
                    const message = e.message;
                    showErrorAlert(message);
                }
            }
        }
        if (!code || !userType || !email) {
            setIsValidating(false);
        } else {
            componentDidMount();
            setShowModal(true);
        }
    }, [userType, code, email, validated, isValidating,setShowModal])

    const renderProfileCreationFlow = () => {
        if(!authUser) return;
        if (!validated || !code) {
            return <div className="container">{isValidating ? <p>Validating...</p> : <p>Failed to validate</p>}</div>
        }
        return (
            <>
                <Modal>
                    <SignIn onlySignIn requiredEmail={email!} hideModal={() => setShowModal(false)} title="Enter your email and password to create a profile." />
                </Modal>
                { userType === 'musician' ? <MusicianProfileSetup user={authUser} /> : <VenueProfileSetup user={authUser} />}
            </>
        )
    }

    return (
        <>
            <Navigation noProfile />
            {renderProfileCreationFlow()}
        </>
    )
}

export default CreateProfile;