import React from 'react';
import { Link } from 'react-router-dom';
import urls from '../../urls';
import './searchUI.scss';

const Search = ({ setQuery, query, placeholder,noConfig }: {
    setQuery: (q: string) => void,
    query: string;
    placeholder: string;
    noConfig?: boolean;
}) => {
    return (
        <div className="search">
            <img src={require('../../assets/search_icon.svg')} alt="" />
            <input type="search" placeholder={placeholder} value={query} onChange={(e) => {
                const { value } = e.target;
                setQuery(value);
            }} />
            {!noConfig && <Link to={urls.SEARCH_CONFIG}>
                <img src={require('../../assets/filters.svg')} alt="" />
            </Link>}
        </div>
    )
}

export default Search;