import React, { useContext, useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import API from '../../firebase';
import { MusicianUser } from '../../interfaces';
import Navigation from '../components/navigation';
import { getYouTubeEmbedUrl } from '../../utilities';
import urls from '../../urls';
import appContext from '../../appContext';
import Loader from 'react-loader-spinner';

const MusicianProfileView = (props: RouteComponentProps) => {
    const { short_user_name } = props.match.params as any;
    const [user, setUser] = useState<MusicianUser>();
    const venueUserContext = useContext(appContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async function () {
            if (short_user_name) {
                let musician = await API.fetchUserWithProperty('url', window.location.host + window.location.pathname);
                setUser(musician[0] as MusicianUser);
            }
            setLoading(false);
        })()
    }, [short_user_name]);

    const website = user?.website && user?.website.length > 25 ? user?.website?.slice(0,25) + '...' : user?.website;

    const showProfile = () => {
        return (
            user ?
                <>
                    <div className="profile-card--images">
                        <div className="profile-card--images__cover" style={user.coverPhotoURL ? ({
                            backgroundImage: `url(${user.coverPhotoURL})`
                        }) : {}}>
                            <input type="file" accept="image/*" hidden />
                        </div>
                        <div className="profile-card--images__profile" style={user.photoURL ? { backgroundImage: `url(${user.photoURL})` } : {}}>
                            <input type="file" accept="image/*" hidden />
                        </div>
                    </div>
                    <div className="container">
                        <div className="details">
                            <div>
                                <h2>{user.stageName}</h2>
                                <p>{user.location?.street_city_state.split(',').slice(1)}</p>
                            </div>
                            <div className="bio-song">
                                <p>{user.bio}</p>
                                <a href={user.website} target="_blank" rel="noopener noreferrer">{website}</a>
                            </div>
                            <p className="musician-profile__experience">{user.experience && `${user.experience} years of performing experience`}</p>
                            <div className="tags">
                                {user.tags?.map((tag, i) => <div key={i} className="genre-tag">{tag}</div>)}
                            </div>
                        </div>
                        {venueUserContext.user?.type === 'venue' ? <Link className="button rounded primary-button send-message-button" to={{
                            pathname: urls.SEND_MESSAGE,
                            state: {
                                email: user.email,
                                stage_name: user.stageName,
                                location: user.location.street_city_state
                            }
                        }}>Send Message</Link> : null}
                        <div className="media">
                            {user.media?.map((media, i) => (
                                <div className="media-card" key={i}>
                                    {
                                        media.type === 'video' ?
                                            <iframe width="560" title={i.toString()} height="315" src={getYouTubeEmbedUrl(media.url)} allowFullScreen></iframe> :
                                            <img src={media.url} alt={media.caption} />
                                    }
                                    <p>{media.caption}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
                : <p className="no-user">User not found</p>
        )
    }
    return (
        <>
            <Navigation withShadow />
            <div className="musician-profile">
                {!loading ? showProfile() : <Loader className="loader" type="TailSpin" />}
            </div>
        </>
    )
}

export default MusicianProfileView;