import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import appContext from '../../appContext';
import API from '../../firebase';
import useSearch from '../../hooks/search';
import { Gig, GigData, MusicianUser, Slot } from '../../interfaces';
import urls from '../../urls';
import GigCard from './gig-card';
import './my-gigs.scss';
import Search from './searchUI';

const MyGigsList = () => {
    type AppGigsAndSlots = {
        gig: Gig,
        slots: Slot[]
    }[];
    const { user } = useContext(appContext);
    const isVenue = user?.type === 'venue';
    const [applications, setApplications] = useState<AppGigsAndSlots>([]);
    const [loading,setLoading] = useState(true);
    const searchableProps = isVenue ? ["gigName"] : ["gigName","venue","address"];
    const { setSearchContent, setQuery, query, results } = useSearch([],searchableProps,true);

    useEffect(() => {
        let isCancelled = false;
        const collectApplications = async () => {
            let musicianUser = user as MusicianUser;
            let application_gig_ids = musicianUser.applications.map(a => a.gig_id);
            if(application_gig_ids.length === 0) return;
            let collectedGigs = await API.collectGigs(application_gig_ids);
            setSearchContent(collectedGigs as GigData[])
            let related_gigs = collectedGigs.filter(g => musicianUser.applications.map(s => s.gig_id).includes(g.id));
            let collectedApps: AppGigsAndSlots = [];
            let application_slot_ids = musicianUser.applications.map(a => a.slot_id);
            related_gigs.forEach(g => {
                let slotsAppliedTo = g.slots!.filter(s => application_slot_ids.includes(s.id));
                collectedApps.push({ gig: g, slots: slotsAppliedTo });
            });
            setApplications(collectedApps)
        }
        if (user) {
            if (user.type === 'venue') {
                user.gig_ids.length ? API.collectGigs(user.gig_ids).then(gigs_ => {
                    !isCancelled && setSearchContent(gigs_ as GigData[])
                }).finally(() => !isCancelled && setLoading(false)) : setLoading(false);
            } else {
                !isCancelled && collectApplications().finally(() => setLoading(false));
            }
        }
        return () => { isCancelled = true }
    }, [user,setSearchContent])


    const renderGigsList = () => {
        if(loading){
            return <Loader type="TailSpin" className="loader" color="#E51968"/>
        }
        if (!results.length) {
            return <p className="nothing-to-show">Nothing to show</p>
        }
        const venueList = (results as Gig[]).map((gig, i) => {
            return <GigCard gig={gig} key={i} />
        });
        const musicianList = applications.filter(a => (results as Gig[]).map(g => g.id).includes(a.gig.id)).map((a, k) => {
            return a.slots.map((s, i) => {
                return <GigCard gig={a.gig} key={i + k} slot={s} />
            });
        }).flat();
        return isVenue ? venueList : musicianList;
    }

    return (
        <section className="my-gigs">
            <Search {...{ query, setQuery }} noConfig placeholder={`Search by ${isVenue ? 'gig name' :'location, gig name, venue'}`} />
            {isVenue && <Link to={urls.CREATE_GIG} className="my-gigs__new-gig-link rounded">Create a new gig</Link>}
            {renderGigsList()}
        </section>
    )
}

export default MyGigsList;