import React, { useEffect, useState } from 'react';
import './App.scss';
import API from './firebase';
import { BrowserRouter as Router, Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import Loading from './pages/loading'; import urls from './urls';
import SignIn from './pages/sign-in';
import SignUp from './pages/sign-up';
import Home from './pages/home';
import PrivateRoute from './hoc/privateRoute';
import NewAccount from './pages/new-account';
import PasswordReset from './pages/password-reset';
import { Config, User } from './interfaces';
import Profile from './pages/profile';
import { Provider } from './appContext';
import CreateProfile from './pages/create-profile';
import UpdateMusicianProfile from './pages/musician/update-profile';
import Account from './pages/account';
import UpdateTags from './pages/update-tags';
import NewGig from './pages/venue/new-gig';
import EditGig from './pages/venue/edit-gig';
import ViewGig from './pages/view-gig';
import MusicianProfileView from './pages/musician/profile-view';
import SendMessage from './pages/venue/send-message';
import SearchConfig from './pages/search-config';
import GigApplication from './pages/musician/gig-application';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import ReviewApps from './pages/venue/review-apps';
import './utilities.scss';
import NotFound from './pages/404';

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [config, setConfig] = useState<Config>({
    activeFilters: [],
    activeSort: 'None'
  });

  useEffect(() => {
    const setContextValues = async (currUser: User | null) => {
      if (currUser) {
        setUser(prev => ({ ...prev, ...currUser } as User));
        currUser.type === 'musician' && setConfig(prev => ({ ...prev, activeFilters: ['None'] }))
      }
      setLoaded(true);
    }
    API.getCurrentUser().then(setContextValues).finally(() => setLoaded(true));
  }, []);

  const handleAuthProcessing = ({ location }: RouteComponentProps<{}>) => {
    const params = new URLSearchParams(location.search);
    const continueURL = params.get('continueUrl');
    let code = `&code=${params.get('oobCode')}`;
    let mode = params.get('mode');

    switch (mode) {
      case 'resetPassword':
        return <Redirect to={{ pathname: urls.PASSWORD_RESET, search: code }} />
      case 'verifyEmail':
        window.location.href = continueURL + code;
        break;
      case 'recoverEmail':
        break
      default:
        break;
    }
  }

  const loadRoutes = () => {
    return <>
      <ReactNotification />
      <Switch>
        <Route exact path={urls.SIGN_IN} component={SignIn} />
        <Route exact path={urls.SIGN_UP} component={SignUp} />
        <Route path={urls.NEW_ACCOUNT + '/:user_type'} component={NewAccount} />
        <Route exact path={urls.PASSWORD_RESET} component={PasswordReset} />
        <Route exact path={urls.CREATE_PROFILE} component={CreateProfile} />
        <Route exact path={urls.AUTH} render={handleAuthProcessing} />
        <PrivateRoute exact path={urls.UPDATE_PROFILE} component={UpdateMusicianProfile} />
        {[urls.HOME,urls.FIND_MUSICIANS,urls.MY_GIGS].map((url,i) => <PrivateRoute key={i} exact path={url} component={Home} />)}
        <PrivateRoute exact path={urls.PROFILE} component={Profile} />
        <PrivateRoute exact path={urls.ACCOUNT} component={Account} />
        <PrivateRoute exact path={urls.CREATE_GIG} component={NewGig} />
        <PrivateRoute exact path={urls.UPDATE_TAGS} component={UpdateTags} />
        <PrivateRoute exact path={urls.VIEW_GIG + '/:id'} component={ViewGig} />
        <PrivateRoute exact path={urls.EDIT_GIG + '/:id'} component={EditGig} />
        <PrivateRoute exact path={urls.GIG_APPLICATION + '/:gig_id/:slot_id'} component={GigApplication} />
        <Route exact path={urls.PROFILE_VIEW + '/:short_user_name'} component={MusicianProfileView} />
        <PrivateRoute exact path={urls.SEND_MESSAGE} component={SendMessage} />
        <PrivateRoute exact path={urls.SEARCH_CONFIG} component={SearchConfig} />
        <PrivateRoute exact path={urls.REVIEW_APPS + '/:id'} component={ReviewApps} />
        <Route component={NotFound} />
      </Switch>
    </>;
  }

  const loadContent = () => {
    if (loaded) {
      return loadRoutes();
    }
    return <Loading />
  }

  const ProviderValue = { user, setUser: (data: User | null) => setUser(data), config, setConfig: (configData: Config) => setConfig(configData) }
  return (
    <Router>
      <Provider value={ProviderValue}>
        {loadContent()}
      </Provider>
    </Router>
  );
}

export default App;
