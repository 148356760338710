import React, { useContext, useRef } from 'react';
import { store } from 'react-notifications-component';
import { useHistory } from 'react-router-dom';
import appContext from '../../appContext';
import API from '../../firebase';
import { AuthUser, User } from '../../interfaces';
import urls from '../../urls';
import { getLocationCoords, showInfoAlert } from '../../utilities';
import './profile-setup.scss';

const VenueProfileSetup = ({ user }: { user: AuthUser }) => {
    const formRef = useRef<HTMLFormElement>(null);
    const history = useHistory();
    const { setUser } = useContext(appContext);

    const handleSubmit = async () => {
        if (formRef.current?.checkValidity()) {
            let data: any = {
                type: 'venue',
                gig_ids: []
            };
            let inputs = formRef.current.querySelectorAll('input');
            let id = showInfoAlert('we are publishing your profile...','Processing',0);
            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].id === 'location') {
                    data['location'] = {
                        street_city_state: inputs[i].value,
                        coordinate: await getLocationCoords(inputs[i].value)
                    }
                } else {
                    data[inputs[i].id] = inputs[i].value;
                }
            }
            await API.setUserProfile({ ...data, email: user.email }, user.uid);
            setUser({ ...user,...data } as User);
            store.removeNotification(id);
            showInfoAlert('Redirecting you to sign-in page','Profile saved');
            history.push(urls.HOME)
        } else {
            window.alert('Please fill in required fields');
        }
    }

    return (
        <div className="container venue-create-profile">
            <h1>Tell us more about you</h1>
            <form onSubmit={handleSubmit} ref={formRef}>
                <div className="form-group">
                    <label htmlFor="name">Venue Name</label>
                    <input type="text" id="name" placeholder="Venue Name" required />
                </div>
                <div className="form-group">
                    <label htmlFor="location">Venue Address</label>
                    <input type="text" id="location" placeholder="Street, City, State" required />
                </div>
                <div className="form-group">
                    <label htmlFor="website">Website Link (Optional)</label>
                    <input type="text" id="website" placeholder="venue.com" />
                </div>
            </form>
            <div>
                <button onClick={handleSubmit} className="primary-button rounded">Save</button>
            </div>
        </div>
    )
}

export default VenueProfileSetup;