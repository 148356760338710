const NEW_ACCOUNT = '/new-account';
const UPDATE_PROFILE = '/update-profile' ;
export default {
    HOST: window.location.protocol + '//'+window.location.host,
    SIGN_IN: '/sign-in',
    SIGN_UP: '/sign-up',
    VENUE_SIGNUP: NEW_ACCOUNT + '/venue',
    MUSICIAN_SIGNUP: NEW_ACCOUNT + '/musician',
    PASSWORD_RESET: '/reset-password',
    CREATE_GIG: '/new-gig',
    EDIT_GIG: '/edit-gig',
    EDIT_GIG_DATES: '/edit-dates',
    VIEW_GIG: '/view-gig',
    NEW_ACCOUNT,
    GIG_APPLICATION: '/gig-apply',
    PROFILE: '/profile',
    CREATE_PROFILE: '/create-profile',
    UPDATE_PROFILE,
    UPDATE_TAGS: UPDATE_PROFILE + '/tags', 
    ACCOUNT: '/account',
    PROFILE_VIEW: '/in',
    SEND_MESSAGE: '/send-message',
    SEARCH_CONFIG: '/search-config',
    AUTH: '/auth',
    HOME: '/',
    FIND_MUSICIANS: '/find-musicians',
    MY_GIGS: '/my-gigs',
    REVIEW_APPS: '/review-applications'
}