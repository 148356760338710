import React, { useEffect, FormEvent, useContext } from 'react';
import SignIn from './sign-in';
import useModal from '../hooks/modal';
import Navigation from './components/navigation';
import useTabbedNav from './components/tabbedNav';
import GigsList from './components/gigs';
import MyGigsList from './components/my-gigs';
import API from '../firebase';
import appContext from '../appContext';
import FindMusicians from './venue/find-musicians';
import { store } from 'react-notifications-component';
import { showErrorAlert, showInfoAlert } from '../utilities';
import './home.scss';
import urls from '../urls';

export default (props: { authMode: boolean }) => {
    const { Modal, setShowModal } = useModal();
    const { user } = useContext(appContext);
    const { ActiveTabComponent, TabbedNav } = useTabbedNav(
        user?.type !== 'venue' ?
            [
                {
                    name: 'Find Gigs',
                    component: GigsList,
                    path: urls.HOME
                },
                {
                    name: 'My Gigs',
                    component: MyGigsList,
                    path: urls.MY_GIGS
                }
            ] : [
                {
                    name: 'My Gigs',
                    component: MyGigsList,
                    path: urls.HOME
                },
                {
                    name: 'Find Musicians',
                    component: FindMusicians,
                    path: urls.FIND_MUSICIANS
                }
            ], true)
    const ResetModal = useModal();

    useEffect(() => {
        let isCancelled = false;
        if (!user && !isCancelled) {
            setShowModal(true);
        }
        return () => { isCancelled = true };
    }, [setShowModal, user])

    const handleReset = async (e: FormEvent) => {
        e.preventDefault();
        const emailInput = (document.querySelector('.password-reset-modal input') as HTMLInputElement);
        let notifyID;
        if (emailInput.checkValidity()) {
            try {
                notifyID = showInfoAlert('We are sending an email with a reset link to your address', 'Sending reset link to' + emailInput.value);
                await API.sendPasswordResetLink(emailInput.value);
                store.removeNotification(notifyID);
                showInfoAlert('Notification email sent', 'Check your inbox for a password reset link');
            } catch (e) {
                const message = e.message;
                showErrorAlert(message);
            } finally {
                notifyID && store.removeNotification(notifyID);
            }
        }
    }

    return (
        <>
            <Navigation />
            <div className="container home">
                <ResetModal.Modal collapsible={true} onClose={() => setShowModal(true)}>
                    <div className="password-reset-modal">
                        <h5>Send password reset to this email</h5>
                        <form className="form-group" onSubmit={handleReset}>
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" placeholder="Email" />
                        </form>
                        <button className="primary-button rounded" onClick={handleReset}>Send Password Reset</button>
                    </div>
                </ResetModal.Modal>
                {props.authMode ?
                    <Modal collapsible={false}>
                        <SignIn ResetsetShowModal={() => {
                            ResetModal.setShowModal(true);
                            setShowModal(false);
                        }} />
                    </Modal>
                    : null}
            </div>
                <TabbedNav />
                <div className="container">
                    <ActiveTabComponent />
                </div>
        </>
    )
}