import React from 'react';
import { useHistory } from 'react-router-dom';
import { MusicianUser } from '../../interfaces';
import urls from '../../urls';
import './musician-card.scss';

const MusicianCard = ({ user }: {
    user: MusicianUser
}) => {
    const history = useHistory();
    const avatarURL = require('../../assets/user-profile.svg');
    const bio = user.bio!.length > 50 ? user.bio?.slice(0, 50) + '...' : user.bio;
    return (
        <div className="musician-card" onClick={() => {
            let regex = user.url.match(/(\w+)$/);
            let username = regex ? regex[0] : '';
            history.push(`${urls.PROFILE_VIEW}/${username}`)
        }}>
            <div>
                <img src={user.photoURL ? user.photoURL : avatarURL} alt="" className="musician-card__profile" />
            </div>
            <div className="musician-card__details">
                <div className="exp-sta">
                    <p className="musician-card__name text-16">{user.stageName}</p>
                    {user.experience && <p className="musician-card__exp text-14">{user.experience} yr(s) of exp.</p>}
                </div>
                <p className="text-14">{user.bio ? bio : 'No bio available'}</p>
                <div className="musician-card__tags">{user.tags?.slice(0, 4).map((t, i) => <li key={i} className="genre-tag">{t}</li>)}</div>
            </div>
        </div>
    )
}

export default MusicianCard;