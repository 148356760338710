import React, { useState } from 'react';
import './radio-button.scss';

const RadioButton = ({ checked,onChange }: { checked: boolean,onChange: () => void }) => {
    const [isOn,setOn] = useState(checked);

    return (
        <div className="radio-button" onClick={() => {
            setOn(prev => !prev);
            onChange();
        }}>
            <input type="checkbox" {...{ checked,onChange }} hidden/>
            {isOn ? <div className="indicator"></div> :null}
        </div>
    )
}

export default RadioButton;