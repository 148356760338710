import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import API from '../../firebase';
import useSearch from '../../hooks/search';
import { MusicianUser } from '../../interfaces';
import MusicianCard from '../components/musician-card';
import Search from '../components/searchUI';
import './find-musicians.scss';

const FindMusicians = () => {
    const { results, setSearchContent, query, setQuery } = useSearch([],["stageName"]);
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            API.fetchUserProfiles().then(users => {
                let musicians = users.filter(user => user.type === 'musician');
                if (isCancelled) {
                    return;
                }
                setSearchContent(musicians as MusicianUser[]);
            }).finally(() => setLoading(false));
        }

        return () => { isCancelled = true }
    }, [setSearchContent]);

    const renderMusicianList = () => {
        if(loading){
            return <Loader type="TailSpin" className="loader" color="#E51968"/>
        }
        if(!results.length){
            return <p>Nothing to show</p>
        }
        return (results as MusicianUser[]).map((musician, i) => <MusicianCard key={i} user={musician} />)
    }

    return (
        <div className="find-musicians">
            <Search {...{ query, setQuery }} placeholder="Search by name" />
            <div className="find-musicians__list">
                {renderMusicianList()}
            </div>
        </div>
    )
}

export default FindMusicians;