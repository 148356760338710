import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import appContext from '../../appContext';
import emailTemplates from '../../email-templates';
import API from '../../firebase';
import useModal from '../../hooks/modal';
import { Gig, Slot, MusicianProfileData, ApplicationRecord, SubmittedApplicationRecord } from '../../interfaces';
import urls from '../../urls';
import { addGigCoverStyling, showErrorAlert, toLocaleTime } from '../../utilities';
import Navigation from '../components/navigation';
import './review-apps.scss';

const Application = ({ app, slot, gig }: { app: SubmittedApplicationRecord, slot: Slot, gig: Gig }) => {
    const [applicant, setApplicant] = useState<MusicianProfileData>();
    const photoURL = applicant?.photoURL ? applicant?.photoURL : require('../../assets/user-profile.svg');
    const { Modal, setShowModal } = useModal();
    const [assignedTo, setAssignedTo] = useState(slot.assignedTo);
    const [applicantWasRejected, setApplicantWasRejected] = useState(app.venueDecision === 'rejected')
    const { user } = useContext(appContext);

    const handleResponse = async (accept: boolean) => {
        try {
            if (accept) {
                acceptApplication();
                waitlistOtherApplicants()
            } else {
                rejectApplicant();
            }
        } catch (e) {
            const message = e.message;
            showErrorAlert(message);
        }
        setShowModal(false);
    }

    const updateApplicantDecision = (decision: 'accepted' | 'rejected' | 'waitlist', user: MusicianProfileData, id: string) => {
        let updatedApplications: ApplicationRecord[] = user.applications!.map(a => a.slot_id === slot.id ? { ...a, venueDecision: decision } : a);
        API.updateUserProfile({ ...user, applications: updatedApplications }, id);
    }

    const acceptApplication = () => {
        assignSlotTo(app.musicianId);
        updateApplicantDecision('accepted', applicant!, app.musicianId);
        let date = (new Date(slot.date)).toDateString();
        let subject = `Congratulations! Your application for ${gig.gigName} on ${date} has been accepted!`;
        let templateContent = {
            email: user?.email,
            date,
            gigName: gig.gigName
        }
        const msg = {
            template_id: emailTemplates.applicationApproval,
            personalizations: [
                {
                    subject,
                    to: [{
                        email: applicant!.email!
                    }],
                    dynamic_template_data: templateContent
                }
            ]
        }
        API.sendEmail(msg);
    }

    const waitlistOtherApplicants = async () => {
        let otherApplicants = await Promise.all(gig.interestedApplicants!.filter(a => a.musicianId !== app.musicianId).map(async a => {
            let userProfile = await API.fetchUser(a.musicianId);
            return { userProfile, id: a.musicianId }
        }));
        otherApplicants.forEach(u => {
            let { userProfile, id } = u;
            updateApplicantDecision('waitlist', userProfile as MusicianProfileData, id);
        });
    }

    const rejectApplicant = () => {
        let date = (new Date(slot.date)).toDateString()
        let subject = `Your application for ${gig.gigName} on ${date} was rejected`;
        const msg = {
            template_id: emailTemplates.applicationRejection,
            personalizations: [
                {
                    subject,
                    to: [{
                        email: applicant!.email!
                    }],
                    dynamic_template_data: {
                        musicianName: applicant?.stageName,
                        gigName: gig.gigName,
                        date
                    }
                }
            ]
        }
        API.sendEmail(msg);
        updateApplicantDecision('rejected', applicant!, app.musicianId);
        setApplicantWasRejected(true);
        let updatedInterestedApplicants = gig.interestedApplicants!.map(a => a.slot_id === slot.id ? { ...a, venueDecision: "rejected" } : a);
        API.updateGig({ stage_image: false, gig_cover: false }, { ...gig, interestedApplicants: updatedInterestedApplicants } as Gig)
    }

    const rescindOffer = () => {
        assignSlotTo('');
        setApplicantWasRejected(true);
        updateApplicantDecision('rejected', applicant!, app.musicianId);
        let date = (new Date(slot.date)).toDateString();
        let subject = `Your offer for ${gig.gigName} on ${date} was rescinded`;
        const msg = {
            template_id: emailTemplates.offerRescind,
            personalizations: [
                {
                    subject,
                    to: [{
                        email: applicant!.email!
                    }],
                    dynamic_template_data: {
                        gigName: gig.gigName,
                        date
                    }
                }
            ]
        }
        API.sendEmail(msg);
    }

    const assignSlotTo = (id: string) => {
        let updatedSlot = { ...slot, assignedTo: id, offer_accepted: false };
        let updatedGig = { ...gig, slots: gig.slots?.map(s => s.id === slot.id ? updatedSlot : s) } as Gig;
        API.updateGig({ gig_cover: false, stage_image: false }, updatedGig);
        setAssignedTo(id);
    }

    const callToActionMessage = () => {
        const acceptOrRejectDecisionPrompt = <p>Are you sure you want to accept {applicant?.stageName} for this slot? If you accept them, all other applicants will be automatically waitlisted.</p>
        const rescindDecisionPrompt = <p>Are you sure you want to rescind your offer?</p>;
        return assignedTo !== app.musicianId ? acceptOrRejectDecisionPrompt : rescindDecisionPrompt;
    }

    const actionControls = () => {
        const acceptOrRejectDecisionControls =
            <>
                <button disabled={assignedTo !== ''} className="link-button" onClick={() => handleResponse(true)}>Accept</button>
                <button onClick={() => handleResponse(false)} className="link-button">Decline</button>
            </>;
        const rescindDecisionControl = <button className="link-button" onClick={() => rescindOffer()}>Rescind offer</button>;
        return assignedTo !== app.musicianId ? acceptOrRejectDecisionControls : rescindDecisionControl;
    }

    useEffect(() => {
        let isCancelled = false;
        if (!applicant && !isCancelled) {
            API.fetchUser(app.musicianId).then(res => {
                if (!isCancelled) {
                    setApplicant((res as MusicianProfileData));
                }
            })
        }
        return () => { isCancelled = true }
    }, [app.musicianId, applicant]);

    const updateFromApplicant = () => {
        if (app.musicianOfferDecision === 'rejected') {
            return <p>This applicant has declined your offer</p>
        }
        if (applicantWasRejected) {
            return <p>You rejected this applicant</p>
        }
    }


    return (
        <div>
            {
                applicant ?
                    <>
                        <Modal collapsible>
                            <h3>Review {applicant.stageName}'s application for {(new Date(slot.date)).toDateString()}, {toLocaleTime(gig!.time_start!)}-{toLocaleTime(gig!.time_end!)} </h3>
                            {callToActionMessage()}
                            <div className="applicant-controls">
                                {actionControls()}
                                <button className="link-button" onClick={() => setShowModal(false)}>Cancel</button>
                            </div>
                        </Modal>
                        <div className="review-applications__applicants--application">
                            <div className="applicant-detail">
                                <div>
                                    <img src={photoURL} alt="user profile" />
                                </div>
                                <div>
                                    <h4>{applicant.stageName}</h4>
                                    {updateFromApplicant()}
                                    <a href={'https://' + applicant?.url} target="_blank" rel="noopener noreferrer">{applicant.url!.length > 25 ? applicant.url!.slice(0, 25) + '...' : applicant.url}</a>
                                </div>
                            </div>
                            {app.musicianOfferDecision !== 'rejected' && !applicantWasRejected ? <button className="rounded primary-button" onClick={() => setShowModal(true)}>Review</button> : null}
                        </div>
                    </>
                    : <i>Applicant does not exist</i>
            }
        </div>
    )
}

const ReviewApps = (props: RouteComponentProps<{}>) => {
    let { id } = props.match.params as any;
    const [gig, setGig] = useState<Gig>();
    const [apps, setApps] = useState<{ [key: string]: SubmittedApplicationRecord[] }>();
    const history = useHistory();
    const { user } = useContext(appContext);

    useEffect(() => {
        let isCancelled = false; //helps clean up stale async 
        const collectSortedSlotApps = async () => {
            if (isCancelled) {
                return;
            }
            let res = await API.collectGigs([id]);
            if (isCancelled) {
                return;
            }
            if (!res.length && !isCancelled) {
                window.alert('Gig does not exist!');
                history.push(urls.HOME);
            }
            let [gig_] = res;
            if (isCancelled) {
                return;
            }
            setGig(gig_);
            let apps_: { [key: string]: SubmittedApplicationRecord[] } = {};
            gig_.interestedApplicants?.forEach(app => {
                //retrieve slot, if slot exists put it in apps with date as key and app as value
                let related_slot = gig_.slots?.find(s => s.id === app.slot_id);
                if (related_slot) {
                    let prev_value = apps_[related_slot.date] ? apps_[related_slot.date] : [];
                    apps_[related_slot.date] = [...prev_value, app]
                }
            });
            if (!isCancelled) {
                setApps(apps_);
            }
        }

        if (user?.type === 'venue') {
            if (!apps && !isCancelled) {
                collectSortedSlotApps();
                if (gig?.hasUnreviewedApps) {
                    API.updateGig({ stage_image: false, gig_cover: false }, { ...gig, hasUnreviewedApps: false });
                }
            }
        } else {
            if (!isCancelled) {
                history.push(urls.HOME);
            }
        }
        return () => { isCancelled = true }
    }, [history, user, setGig, id, gig, apps]);

    const renderApplicants = () => {
        return (apps !== undefined ? Object.entries(apps).map(([date, appList], k1) => {
            //Slot must exist, otherwise this app list won't be considered
            let slot = gig!.slots?.find(s => s.date === date)!;
            return (
                <div key={k1} className="review-applications__slot">
                    <div className="slot_details">
                        <h2>{(new Date(date)).toDateString()}</h2>
                        <p>{toLocaleTime(gig!.time_start!)}-{toLocaleTime(gig!.time_end!)}</p>
                    </div>
                    <div>
                        {appList.map((app, k2) => <Application {...{ app, slot, gig: gig! }} key={k2 + k1} />)}
                    </div>
                </div>
            )
        }) : null)
    };

    return (
        <>
            <Navigation withShadow />
            {
                gig ?
                    <div className="review-applications">
                        <div className="review-applications__applicants container">
                            <div className="gig-cover" style={addGigCoverStyling(gig.gig_cover_url)}></div>
                            <h1>Gig applications for {gig.gigName}</h1>
                            {renderApplicants()}
                        </div>
                    </div> : <p>Loading...</p>
            }
        </>
    )
}

export default ReviewApps;