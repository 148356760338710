import React from 'react';
import { User, Config } from './interfaces';

let value: {
    user: User | null,
    setUser: (data: User| null) => void,
    config: Config,
    setConfig: (configData: Config) => void
} = {
    user: null,
    setUser: () => {},
    config: {
        activeFilters: [],
        activeSort: ''
    },
    setConfig: (configData: Config) => {}
};
const appContext = React.createContext(value);
export const { Provider,Consumer } = appContext;

export default appContext;